<template>
    <div class="wap_container">
        <router-view> </router-view>
        <van-dialog v-model="$store.state.loginModalVisible" show-cancel-button :show-confirm-button="isWX"
            cancel-button-text="知道了" confirm-button-text="去登录" confirm-button-color="#000" @confirm="toLogin">
            <div class="text">该服务需要登录后使用</div>
        </van-dialog>
    </div>
</template>

<script>
import { wxLoginPath, environment } from '@/appConfigData.js'
export default {
    data() {
        return {
            env: environment
        }
    },
    methods: {
        toLogin() {
            this.$wx.miniProgram.navigateTo({
                url: `${wxLoginPath}`,
            })
        }
    },
    computed: {
        isWX() {
            return this.env === 'wx'
        }
    }
}
</script>

<style lang="less" scoped>
.wap_container {
    width: 100%;
    height: 100vh;
}

.text {
    width: 100%;
    padding: 20px;
    text-align: center;
}
</style>