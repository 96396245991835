<template>
    <div class="quick_container">
        <div class="login_block" @click="handleTouchStart"></div>
        <div class="login_input" v-if="show">
            <van-cell-group>
                <van-field v-model="Account" label="账号" placeholder="请输入账号" />
                <van-field v-model="Verifycode" label="密钥" placeholder="请输入密钥" />
            </van-cell-group>
            <van-button @click="handleEnterKey">登录</van-button>
        </div>
    </div>
</template>

<script>
import { PhoneLoginOfSpecific } from '@/api/login.js'
export default {
    data() {
        return {
            Account: '',
            Verifycode: '',
            show: false,
            touchStartTime: 0,
            touchEndTime: 0,
            num: 0
        }
    },
    methods: {
        async handleEnterKey() {
            let res = await PhoneLoginOfSpecific({
                Account: this.Account,
                Verifycode: this.Verifycode
            })
            if (res.IsSuccess) {
                localStorage.setItem("loginToken", res.Data.access_token);
                this.$store.commit('setLoginState', true)
                this.$router.push("/");
            }
        },
        checkDoubleClick() {
            this.show = !this.show
        },
        handleTouchStart(e) {
            this.num = this.num + 1
            if (this.num === 1) {
                this.touchStartTime = new Date().getTime();
            } else if (this.num === 2) {
                this.touchEndTime = new Date().getTime();
                if (this.touchEndTime - this.touchStartTime < 500) {
                    this.num = 0
                    this.checkDoubleClick()
                } else {
                    this.num = 0
                }
            }

        },
    },
    mounted() {
        localStorage.clear()
    }
}
</script>

<style lang="less" scoped>
.quick_container {
    width: 100%;
    height: 100vh;
    position: reactive;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F8F8F8;

    .login_block {
        position: absolute;
        left: 0;
        top: 0;
        width: 150px;
        height: 150px;
    }

    .login_input {
        display: flex;
        flex-direction: column;
        background-color: #FFF;
        padding: 25px;
        gap: 10px;
        width: 250px;
    }
}
</style>