<template>
    <div class="speed-match" v-track="{ triggerType: 'show', info: operateText.match }">
        <PageLoading :loading="pageLoading">匹配中...</PageLoading>
        <Header :data="matchResult" />
        <MatchedProjects :projectList="matchedProjects" class="projects" :evaluationId="evaluationId" @on-search="onSearch">
            <template>
                <div class="tips">
                    <h3 class="title">
                        为您匹配到政策项目<span class="count">{{ isSearch ? searchDataList.length : matchedProjectCount }}</span>个
                    </h3>
                    <p class="min-txt">进入项目详情页，查看项目相关政策、通知、公示</p>
                </div>
            </template>
        </MatchedProjects>
        <div class="footer">
            <div class="row">
                <!-- <button class="vice-btn" @click="toPerfectInfo">
                    精准匹配
                </button> -->
                <DownloadPlanReport :companyId="companyId" @on-download="downloadPlanReport" />
            </div>
        </div>
    </div>
</template>
  
<script>
import { pageOperate } from '@/appConfigData.js'
import mixin from "./mind-match/mixin";
import PageLoading from '@/components/page-loading.vue'
export default {
    mixins: [mixin],
    components: { PageLoading },
    data() {
        return {
            dataList: [],
            operateText: pageOperate,
        };
    },
    created() { },
    methods: {},
};
</script>
  
<style scoped lang="less">
@import "./mind-match/common.less";
</style>