import { request } from '../http/request'
import config from '@/serviceConfig/service'
const { host, prefix, serviceApi } = config
const { policypro } = host
const { api } = prefix
const { policyLogin, phoneLoginOfSpecific } = serviceApi

export const tokenLogin = (data) => {
    return request({
        url: policypro + policyLogin,
        method: 'post',
        data,
    })
}

// 快捷登录
export const PhoneLoginOfSpecific = (data) => {
    return request({
        url: policypro + phoneLoginOfSpecific,
        method: 'post',
        data,
    })
}

