import Vue from 'vue'
import { request } from '../http/request'
import config from '@/serviceConfig/service'
const { host, prefix, serviceApi } = config
const { policypro } = host
const { addOperationLog } = serviceApi
let params = {}
// v-track="{ triggerType: 'show', info: {PageName: '政策汇集', EventNam: '列表访问', DeviceType: 1} }"
// v-track="{ triggerType: 'click'/'show', info: { PageName: '政策汇集，政策速递，政策推荐,政策申报指引报告下载', EventNam '列表访问，详情访问，政策申报指引报告下载', DeviceType: 1} }"
// 自定义埋点指令
Vue.directive('track', {
    //只调用一次，指令第一次绑定到元素时调用。在这里可以进行一次性的初始化设置
    bind: (el, binding, vnode) => {
        if (binding.value.triggerType === 'click') {
            el.addEventListener('click', () => {
                request({
                    url: policypro + addOperationLog,
                    method: 'post',
                    data: binding.value.info,
                })
            })
        }
        if (binding.value.triggerType === 'show') {
            console.log(binding)
            request({
                url: policypro + addOperationLog,
                method: 'post',
                data: binding.value.info,
            })
        }
    }
})