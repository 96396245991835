<template>
  <div class='user-location'>
    <div @click="isShowPickerArea = true">
      <slot>
        <div class="mark van-ellipsis">
          <img src="@/static/image/LocationIcon.png" alt class="icon" />
          <span class="text">{{ regionName }}</span>
        </div>
      </slot>
    </div>
    <!--区域选择-->
    <van-popup v-model="isShowPickerArea" position="bottom" :close-on-click-overlay="false">
      <van-area :area-list="areaData" :value="'430000'" @confirm="confirmLocation" cancel-button-text=" "
        columns-num="2" />
    </van-popup>
  </div>
</template>

<script>
import areaData from '@/tool/area-data';
export default {
  components: {},
  data() {
    return {
      areaData,
      regionName: '全国',
      isShowPickerArea: false,
      locationData: {}
    }
  },
  mounted() {
    // this.pickerArea()
  },
  methods: {
    pickerArea() {
      this.isShowPickerArea = true;
    },
    refreshLocation() {
      const area = JSON.safeParse(localStorage.getItem("area"));
      localStorage.setItem('area', JSON.stringify(area));
    },
    //设置为默认定位
    setDefaultLocation() { },
    //确认选择位置时
    confirmLocation(data) {
      this.isShowPickerArea = false;
      const area = {
        areaCode: data[1].code,
        regionName: data[0].name + data[1].name,
        province: data[0].name,
        provinceId: data[0].code,
        city: data[1].name,
        cityId: data[1].code
      };
      this.regionName = data[1].name
      this.$emit('on-location', area);
    },
  }
}
</script>

<style scoped lang="less">
.mark {
  display: flex;
  align-items: center;

  .icon {
    width: 10px;
    margin-right: 4px;
  }

  .text {
    font-size: 12px;
    line-height: 1;
    color: #333;
  }
}
</style>
