<template>
  <div class="project-list">
    <template v-for="(item, index) in renderData">
      <van-swipe-cell :key="index" :disabled="!deleleable">
        <ProjectCard :searchKey="searchKey" :project="item" v-bind="$attrs" toDetail
          @onTapCultivation="isShowRemark = true" />
        <template slot="right">
          <van-button square text="删除" type="danger" class="delete-button"
            @click="$emit('on-del-project', index, item)" />
        </template>
      </van-swipe-cell>
    </template>
    <van-popup v-model="isShowRemark" closeable close-icon="close" position="bottom" :style="{ height: '40%' }">
      <div style="padding:40px 20px 20px 20px;font-size:14px;">可培育项目是指企业暂未符合该项目申报条件，但通过专业咨询师指导，可在较短时间内、以较低的成本达到项目申报条件
      </div>
    </van-popup>
  </div>
</template>
<script>
import ProjectCard from '@/components/project-card';
import { mapGetters, mapState } from "vuex";
export default {
  name: "Project",
  props: {
    projectData: {
      default: () => {
        return [];
      },
      type: Array
    },
    deleleable: {
      type: Boolean,
      default: false
    },
    searchKey: {
      type: String,
      default: ""
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
    renderData: function () {
      return this.projectData;
    }
  },
  components: {
    ProjectCard
  },
  methods: {
    ...mapGetters({
      UserInfo: "getUserInfo"
    }),
    ListLink(pid) {
      if (this.$store.state.loginState === false) {
        this.$store.commit('setLoginModalVisible', true)
        return
      }
      if (this.$route.meta.otherPage) {
        this.$router.push({
          path: "/other-project-detail",
          query: {
            id: pid
          },
        });
      } else {
        this.$router.push({
          path: "/project/project-detail",
          query: {
            id: pid
          }
        });
      }
    }
  },
  data() {
    return {
      isShowRemark: false,
      path: '',
      pageName: ""
    };
  },
  beforeMount() {
    let path = this.$route.path
    if (path == '/') {
      this.path = path
      this.pageName = '首页-推荐项目'
    }
  }
};
</script>

<style lang="less" scoped>
.project-list {
  background: #fff;
}

.delete-button {
  height: 100%;
}

/deep/ .van-swipe-cell__right {
  right: -1px;
}
</style>
