import Vuex from 'vuex'
import Vue from 'vue'
Vue.use(Vuex)
//用于响应组件中的动作
const actions = {}

//准备mutation   用于提交mutation修改state
const mutations = {
    setArea(state, val) {
        state.area = val
    },
    setLoginModalVisible(state, val) {
        state.loginModalVisible = val
    },
    setLoginState(state, val) {
        state.loginState = val
    }
}

//用于将state里的数据进行加工
const getters = {
}

//用于存储数据
const state = {
    area: {},
    // 是否已登录
    loginState: false,
    loginModalVisible: false,
}

const store = new Vuex.Store({
    actions,
    mutations,
    state,
    getters
})

export default store