var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"track",rawName:"v-track",value:({ triggerType: 'show', info: _vm.operateText.projectList }),expression:"{ triggerType: 'show', info: operateText.projectList }"}],staticClass:"project-list"},[_c('van-sticky',[_c('div',{staticClass:"top"},[_c('UserLocation',{ref:"userLocationRef",on:{"on-location":_vm.onLocationChange}}),_c('van-search',{attrs:{"shape":"round","placeholder":"请输入搜索关键词"},on:{"search":_vm.search},model:{value:(_vm.searchForm.SearchKey),callback:function ($$v) {_vm.$set(_vm.searchForm, "SearchKey", $$v)},expression:"searchForm.SearchKey"}})],1),_c('van-dropdown-menu',[_c('van-dropdown-item',{attrs:{"options":_vm.monthOps},on:{"change":_vm.search},model:{value:(_vm.searchForm.months),callback:function ($$v) {_vm.$set(_vm.searchForm, "months", $$v)},expression:"searchForm.months"}}),_c('van-dropdown-item',{attrs:{"options":_vm.categoryOps},on:{"change":_vm.search},model:{value:(_vm.searchForm.classID),callback:function ($$v) {_vm.$set(_vm.searchForm, "classID", $$v)},expression:"searchForm.classID"}}),_c('van-dropdown-item',{attrs:{"options":_vm.systemOps},on:{"change":_vm.search},model:{value:(_vm.searchForm.ReleaseIds),callback:function ($$v) {_vm.$set(_vm.searchForm, "ReleaseIds", $$v)},expression:"searchForm.ReleaseIds"}}),_c('van-dropdown-item',{ref:"moreFilter",staticClass:"more-filter",attrs:{"title":"更多筛选"}},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v("级别")]),_c('ul',{staticClass:"ops"},_vm._l((_vm.levelOps),function(item,index){return _c('li',{key:index,class:[
                            'option',
                            { active: item.value === _vm.searchForm.gradeID },
                        ],on:{"click":function($event){(_vm.searchForm.gradeID = item.value), _vm.search()}}},[_vm._v(" "+_vm._s(item.text)+" ")])}),0)]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v("重点领域")]),_c('ul',{staticClass:"ops"},_vm._l((_vm.focusAreaOps),function(item,index){return _c('li',{key:index,class:[
                            'option',
                            { active: item.value === _vm.searchForm.CategoryName },
                        ],on:{"click":function($event){(_vm.searchForm.CategoryName = item.value)}}},[_vm._v(" "+_vm._s(item.text)+" ")])}),0)]),_c('div',{staticClass:"bottom"},[_c('van-button',{staticClass:"close-btn",attrs:{"color":"#df2b36"},on:{"click":function($event){return _vm.$refs.moreFilter.toggle(false)}}},[_vm._v("关闭")])],1)])],1),(_vm.searchText)?_c('div',{staticClass:"search-text"},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.searchText))]),_c('van-icon',{staticClass:"icon",attrs:{"name":"clear"},on:{"click":_vm.clearSearchCond}})],1):_vm._e(),_c('div',{staticClass:"total"},[_vm._v("共"+_vm._s(_vm.total)+"个项目")])],1),_c('PageList',{ref:"projectList",staticClass:"projects",attrs:{"getListFn":_vm.getProjects,"emptyText":"暂时找不到相关项目","page-size":20},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [_c('Projects',{attrs:{"projectData":slotProps.list,"searchKey":_vm.searchForm.SearchKey}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }