import { request } from '../http/request'
import config from '@/serviceConfig/service'
const { host, prefix, serviceApi } = config
const { policypro } = host
const { api } = prefix
const { getAdoptDetails, getAboutProjectList, getPolicyAttachmentList, getCollection, getAboutInfoList, getPolicyList, getByProjectInstantList } = serviceApi

export const GetAdoptDetails = (data) => {
    return request({
        url: policypro + api + getAdoptDetails,
        method: 'get',
        data,
    })
}

export const GetAboutProjectList = (data) => {
    return request({
        url: policypro + api + getAboutProjectList,
        method: 'get',
        data,
    })
}

export const GetPolicyAttachmentList = (data) => {
    return request({
        url: policypro + api + getPolicyAttachmentList,
        method: 'get',
        data,
    })
}

export const GetCollection = (data) => {
    return request({
        url: policypro + api + getCollection,
        method: 'get',
        data,
    })
}

export const GetAboutInfoList = (data) => {
    return request({
        url: policypro + api + getAboutInfoList,
        method: 'get',
        data,
    })
}

export const GetPolicyList = (data) => {
    return request({
        data,
        method: 'post',
        url: policypro + getPolicyList,
    })
}

export const GetByProjectInstantList = (data) => {
    return request({
        data,
        method: 'get',
        url: policypro + api + getByProjectInstantList,
    })
}