import { request } from '../http/request'
import config from '@/serviceConfig/service'
const { host, prefix, serviceApi } = config
const { policypro } = host
const { api } = prefix
const { getHomeProjectList } = serviceApi

export const GetHomeProjectList = (data) => {
    return request({
        url: policypro + getHomeProjectList,
        method: 'post',
        data,
    })
}