<template>
  <div class="zx_home">
    <div class="zx_bar_list">
      <div class="zx_bar_card">
        <div v-for="item of menuList" class="zx_card_item" @click="onJump(item.type)">
          <img :src="item.imgSrc" alt="" srcset="">
          <span>{{ item.name }}</span>
        </div>
        <!-- <div class="zx_card_item" @click="onJump('project')">
          <img src="@/static/image/icon_zcst@2x.png" alt="" srcset="">
          <span>政策速递</span>
        </div>
        <div class="zx_card_item" @click="onJump('match')">
          <img src="@/static/image/icon_zctj@2x.png" alt="" srcset="">
          <span>智能推荐</span>
        </div> -->
      </div>
    </div>
    <div class="zx_list">
      <div class="zx_list_head">
        <div>近期政策项目</div>
        <div @click="onJump('project')">查看更多</div>
      </div>
      <div class="zx_list_panle">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
            <div class="zx_list_item" v-for="(project, index) of projectList" @click="onPush(project)">
              <div class="item_left">
                <div class="item_tag">{{ project.ClassName }}</div>
                <div class="item_num">{{ project.SupportExplain }}</div>
                <div class="item_grade">{{ project.GradeName }}</div>
              </div>
              <div class="item_right">
                <div class="item_address">{{ project.Department }}</div>
                <div class="item_name" v-if='project.ProjectTitleHot'>{{ project.ProjectTitleHot }}</div>
                <div class="item_name" v-else>{{ project.ProjectTitle }}</div>
                <!-- <div class="item_time" v-show="project.StartTime != '' && project.EndTime != ''">
                  {{ project.StartTime }}至{{ project.EndTime }}
                </div> -->
                <div class="item_time">{{ project.DeclarationDesc }}</div>
              </div>
              <div class="tag" v-if="project.IsCultivation">可培育</div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
  </div>
</template>

<script>
import { GetHomeProjectList } from "@/api/home.js";
import { list } from '@/appConfigData.js'
export default {
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      pageSize: 0,
      projectList: [],
      menuList: list
    }
  },
  methods: {
    async onLoad() {
      this.loading = true
      this.pageSize = this.pageSize + 10
      const res = await GetHomeProjectList({ page: 1, pageSize: this.pageSize })
      if (res.success) {
        this.projectList = res.Data
        this.loading = false
        if (res.RecordCount === this.projectList.length) {
          this.finished = true
        }
      } else {
        this.loading = false
        this.$toast('获取项目列表失败!');
      }
    },
    async onRefresh() {
      this.refreshing = true
      const res = await GetHomeProjectList({ page: 1, pageSize: this.pageSize })
      if (res.success) {
        this.refreshing = false
        this.projectList = res.Data
      } else {
        this.$toast('项目列表刷新失败!');
      }
    },
    onJump(path) {
      switch (path) {
        case 'policy':
          this.$router.push({
            path: '/other-policy'
          })
          break;
        case 'project':
          this.$router.push({
            path: '/other-project'
          })
          break;
        case 'match':
          if (this.$store.state.loginState === false) {
            this.$store.commit('setLoginModalVisible', true)
            return
          }
          this.$router.push({
            path: '/other-speed-match'
          })
          break;
      }
    },
    onPush(project) {
      if (this.$store.state.loginState === false) {
        this.$store.commit('setLoginModalVisible', true)
        return
      }
      this.$router.push({
        path: "/other-project-detail",
        query: {
          id: project.PID
        },
      });
    }
  }
}
</script>

<style lang="less" scoped>
.zx_home {
  width: 100%;
  height: 100vh;
  background-color: #F5F6FA;

  .zx_bar_list {
    width: 100%;
    height: 117px;
    background-image: url('@/static/image/piic_hqzc_bg@2x.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 12px;
    margin-bottom: 12px;

    .zx_bar_card {
      width: 100%;
      height: 100%;
      background-color: #FFF;
      border-radius: 10px;
      padding: 12px 22px;
      display: flex;

      .zx_card_item {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #000000;

        img {
          width: 45px;
          height: 45px;
        }
      }
    }
  }

  .zx_list {
    width: 100%;
    height: calc(100vh - 117px - 12px);
    background-color: #FFFFFF;

    .zx_list_head {
      width: 100%;
      height: auto;
      max-height: 41px;
      padding: 12px 16px 6px 16px;
      border-bottom: 1px solid #EEEEEE;
      display: flex;
      justify-content: space-between;
      color: #333333;

      :first-child {
        font-size: 16px;
        font-weight: 500;
      }

      :last-child {
        font-size: 12px;
        font-weight: 500;
      }
    }

    .zx_list_panle {
      width: 100%;
      height: calc(100vh - 117px - 41px - 12px);
      padding: 0 15px;
      overflow-y: scroll;

      .zx_list_item {
        width: 100%;
        height: auto;
        padding: 15px 0 14px 0;
        display: flex;
        position: relative;

        .item_left {
          width: auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          border-right: 1px solid #EEE;
          padding-right: 12px;
          min-height: 61px;

          .item_tag {
            font-size: 11px;
            width: 54px;
            height: 18px;
            padding: 2px 5px;
            background: rgba(59, 132, 200, 0.1);
            font-weight: 400;
            color: #3B84C8;
            white-space: nowrap;
          }

          .item_num {
            text-align: center;
            width: 68px;
            font-size: 14px;
            font-weight: 400;
            color: #3B84C8;
            margin: 3px 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .item_grade {
            font-size: 12px;
            font-weight: 400;
            color: #999999;
          }
        }

        .item_right {
          flex: 1;
          padding-left: 12px;

          .item_address {
            font-size: 12px;
            font-weight: 400;
            color: #333333;
            max-width: 250px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .item_name {
            font-size: 14px;
            font-weight: 500;
            color: #333333;
            margin: 3px 0;
            max-width: 250px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .item_time {
            font-size: 11px;
            font-weight: 400;
            color: #999999;
          }
        }
      }

      .zx_list_item:not(:last-child) {
        border-bottom: 1px solid #EEE;
      }

      .tag {
        position: absolute;
        padding: 1px 12px;
        width: auto;
        height: 18px;
        background: rgba(225, 188, 109, 0.2);
        right: 0;
        top: 0;
        font-size: 11px;
        font-weight: 400;
        color: #E5C47E;
        border-radius: 0px 0px 2px 2px;
      }
    }
  }
}
</style>