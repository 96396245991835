<!--  -->
<template>
  <div class="page-list" ref="pageList">
    <van-pull-refresh v-model="pullIng" :disabled="!pullRefresh" @refresh="onPullRefresh" success-text="刷新成功"
      class="pull-refresh">
      <van-list v-model="listLoading" @load="listLoad" :finished="listFinished" finished-text="">
        <slot :list="list"></slot>
      </van-list>
      <div class="no-data-tips" v-show="listFinished">
        <div v-show="list.length">
          <slot name="finished">{{ finishedText }}</slot>
        </div>
        <div v-show="!list.length">
          <slot name="empty">
            <van-empty :description="emptyText" />
          </slot>
        </div>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  props: {
    pullRefresh: {
      type: Boolean,
      default: true,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    emptyText: {
      type: String,
      default: "暂无数据",
    },
    finishedText: {
      type: String,
      default: "没有更多了",
    },
    getListFn: {
      type: Function,
      required: true,
    },
    initGet: {
      type: Boolean,
      default: true,
    },
    //是否输出结果
    showLog: {
      type: Boolean,
      default: false,
    },
    fullScreen: {
      type: Boolean,
      default: true
    },
    scrollTop: {
      type: Boolean,
      default: true
    }
  },
  data() {
    //这里存放数据
    return {
      list: [],
      pullIng: false,
      listFinished: false,
      listLoading: true,
      pageIndex: 1,
      total: 0
    };
  },
  methods: {
    listLoad() {
      this.pageIndex++;
      this.getList(true);

    },
    getList(isLoadMore = false) {
      if (this.listFinished) return;
      this.listLoading = true;
      this.getListFn({
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }, this.list, this.total)
        .then((res) => {
          if (res._done) {
            this.listFinished = true;
            return;
          }
          if (this.showLog) console.log(res, res.Data);
          this.total = res.RecordCount || 0;
          if (isLoadMore) {
            this.list = this.list.concat(res.Data || []);
          } else {
            this.list = res.Data || [];
          }
          if (this.list.length >= res.RecordCount || res.Data.length < this.pageSize) {
            this.listFinished = true;
          }
        })
        .catch(err => {
          this.listFinished = true;
        })
        .finally(() => {
          this.pullIng = false;
          this.listLoading = false;
        });
    },
    onPullRefresh() {
      this.$emit('on-refresh');
      this.pullIng = true;
      this.refresh();
    },
    refresh() {
      if (this.scrollTop) $("html,body").scrollTop(0);
      this.listFinished = false;
      this.pageIndex = 1;
      this.getList();
    },
  },
  created() {
    this.initGet && this.getList();
  },
  mounted() {
    if (this.fullScreen) {
      const pageList = $(this.$refs.pageList);
      const height = $(window).height() - pageList.offset().top;
      pageList.find('.van-pull-refresh').css('min-height', height);
      pageList.find('.van-pull-refresh__track').css('min-height', height);
    }
  }
};
</script>
<style lang='less' scoped>
.no-data-tips {
  padding: 10px 0;
  text-align: center;
  color: #8f8f8f;
  font-size: 14px;
}
</style>