<!--  -->
<template>
    <div class="box" ref="box" v-track="{ triggerType: 'show', info: operateText.policyList }">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-sticky>
                <div class="searchMain">
                    <div>
                        <div class="search-box">
                            <UserLocation @on-location="onLocationChange" ref="userLocationRef"
                                style="margin-right: 8px;" />
                            <div class="searchMainT searchBar-input" style="flex:1">
                                <!-- <input type="search" id="searchInp" ref="searchInp" :value="SearchKey" placeholder="请输入搜索关键词"> -->
                                <form action="/">
                                    <van-search background="#f0f0f0" @search="Search()" @clear="Search()"
                                        @input="getSearchInfo()" ref="searchInp" clearable placeholder="请输入关键字查询"
                                        v-model="SearchKey" style="height:32px;border-radius: 100px;"
                                        @click="showSearchList = true" @blur="showSearchList = false" />
                                </form>
                            </div>
                        </div>
                        <div class="searchInfoList" v-if="searchInfoList && searchInfoList.length > 0 && showSearchList">
                            <div class="searchName" v-for="(item, index) in searchInfoList" :key="index"
                                @click="setSearchKey(item)">{{ item }}</div>
                        </div>
                    </div>
                    <van-dropdown-menu>
                        <van-dropdown-item v-model="postType" @change="PolicyCategoryQuery" :options="postTypeList" />
                        <van-dropdown-item v-model="Release" @change="ServiceSystemQuery" :options="ReleaseList" />
                        <van-dropdown-item :title="Years" ref="item">
                            <van-picker title="1" :columns="columns" ref="time" :show-toolbar="false" />
                            <div style="display:flex;">
                                <van-button block style="margin-right:4px;"
                                    color="linear-gradient(131deg, rgba(241, 129, 86, 1) 0%, rgba(223, 43, 54, 1) 100%)"
                                    type="info" @click="onClear">清空</van-button>

                                <van-button block
                                    color="linear-gradient(131deg, rgba(241, 129, 86, 1) 0%, rgba(223, 43, 54, 1) 100%)"
                                    type="info" @click="onChange">确认</van-button>
                            </div>
                        </van-dropdown-item>
                        <van-dropdown-item title="更多筛选" ref="morCondition" v-if="!true">
                            <div class="more-level">
                                <span class="more-title">级别</span>
                                <div class="level-context">
                                    <span>国家级</span>
                                    <span>省级</span>
                                    <span>市级</span>
                                    <span>区县级</span>
                                </div>
                            </div>
                            <div class="c-type">
                                <span class="more-title">企业类型</span>
                                <div class="type-context">
                                    <span>规上企业</span>
                                    <span>科技企业</span>
                                    <span>服务机构</span>
                                    <span>园区平台</span>
                                </div>
                            </div>
                        </van-dropdown-item>
                    </van-dropdown-menu>
                    <!-- <img :src="followAd.Url" style="max-height:110px;" width="100%" alt /> -->
                </div>
            </van-sticky>
            <div class="PolicyMain" ref="PolicyMain">
                <van-list v-model="loading" :finished="finished" @load="onLoad" :immediate-check="false" :error.sync="error"
                    error-text="请求失败，点击重新加载">
                    <div v-for="item in ListArr" class="PolicyList a-active"
                        @click="ListLink(item.PID, item.ChannelId, item.PolicyTitle)" :key="item.PID">
                        <p class="PolicyListT">{{ item.Source }}</p>
                        <p class="PolicyTitle" v-html="setHtml(item.PolicyTitle)"></p>
                        <div class="PolicyListB">
                            <div class="PolicyListB-l">
                                <span class="Label">{{ item.ChannelName }}</span>
                                {{ item.ReleaseDate }} 阅读{{ item.Reads }}
                            </div>
                        </div>
                    </div>
                </van-list>
            </div>
            <!--无数据提示-->
            <div class="NoData" v-if="IsData && ListArr.length > 0" :style="{ minHeight: height + 'px' }">
                <i class="weui-icon-warn weui-icon_msg-primary"></i>
                <p>暂时找不到相关内容</p>
            </div>
        </van-pull-refresh>
        <!-- <div style="height:46px;"></div> -->
        <!--底部隔层-->
        <div class="Interlayer"></div>
    </div>
</template>
  
<script>
import UserLocation from '@/components/user-location';
import { pageOperate } from '@/appConfigData.js'
import { removeEmptyValues } from '@/tool/utiles';
import { GetDictionary } from "@/api/base.js";
import { GetPolicyList, GetByProjectInstantList } from '@/api/policy.js'
import $ from 'jquery';
export default {
    name: 'PolicyList',
    components: {
        UserLocation
    },
    data() {
        //这里存放数据
        return {
            columns: [],
            refreshing: false,
            finished: false,
            pageIndex: 1,
            pageSize: 10,
            loading: false,
            error: false,
            ListArr: [],
            IsData: false,
            SearchKey: "",
            searchInfoList: [],
            showSearchList: false,
            postType: 99,
            areaCode: '',
            postTypeList: [
                { text: "政策类别", value: 99 },
                { text: "政策文件", value: 1 },
                { text: "申报通知", value: 2 },
                { text: "申报公示", value: 3 }
            ],
            Release: '',
            ReleaseList: [],
            Years: "年",
            selectYears: "",
            ScrollTop: 0,
            operateText: pageOperate
        };
    },
    computed: {
        // userLocation() {
        //     return this.$store.state.userLocation || {};
        // },
    },
    //方法集合
    methods: {
        setHtml(data) {
            const div = document.createElement("div");
            div.innerHTML = data;
            let innerText = div.innerText;
            const nodeList = div.querySelectorAll("span");
            nodeList.forEach(node => {
                var txt = node.innerText;
                innerText = innerText.replaceAll(txt, "<span>" + txt + "</span>")
            });
            if (this.SearchKey) innerText = innerText.replaceAll(this.SearchKey, `<span style='color: #EA2D3F'>${this.SearchKey}</span>`)
            return innerText
        },
        onLocationChange(area) {
            $("html,body").scrollTop(0);
            this.areaCode = area.areaCode;
            this.finished = false;
            this.pageIndex = 1;
            this.GetList();
        },
        ServiceSystemQuery(val) {
            $("html,body").scrollTop(0);
            this.Release = val;
            this.pageIndex = 1;
            this.finished = false;
            this.GetList();
        },
        PolicyCategoryQuery(val) {
            $("html,body").scrollTop(0);
            this.postType = val;
            this.pageIndex = 1;
            this.finished = false;
            this.GetList();
        },
        async GetServiceSystem() {
            const res = await GetDictionary({ rootid: 10624 })
            if (res.success) {
                this.ReleaseList = res.Data.map(item => {
                    return {
                        text: item.name,
                        value: item.code
                    };
                });
                this.ReleaseList.unshift({ text: "发文系统", value: "" })
            }
        },
        changeTime(obj) {
            this.selectYears = obj.getValues()[0];
        },
        async GetList(IsCover) {
            this.loading = true;
            let params = {
                PageIndex: this.pageIndex,
                PageSize: this.pageSize,
                postType: this.postType,
                SearchKey: this.SearchKey,
                Release: this.Release,
                years: this.Years == "年" ? "" : this.Years,
                Area: this.areaCode
            }
            const res = await GetPolicyList({ ...removeEmptyValues(params) })
            if (res.success) {
                this.refreshing = false;
                this.loading = false;
                const data = res.Data || [];
                if (data.length <= 0) {
                    this.finished = true;
                    return false;
                } else {
                    if (this.IsData) {
                        this.IsData = false;
                    }
                }
                this.ListArr = IsCover ? this.ListArr.concat(data) : data;
            } else {
                this.finished = true;
                this.loading = false;
            }
        },
        onClear() {
            this.Years = "年";
            this.pageIndex = 1;
            this.finished = false;
            $("html,body").scrollTop(0);
            this.$refs.item.toggle();
            this.GetList();
        },
        onChange() {
            this.Years =
                this.$refs.time.getValues()[0] == "全部"
                    ? "年"
                    : this.$refs.time.getValues()[0] + "";
            this.pageIndex = 1;
            this.finished = false;
            $("html,body").scrollTop(0);
            this.$refs.item.toggle();
            this.GetList();
        },
        ListLink(pid, type, item) {
            if (this.$store.state.loginState === false) {
                this.$store.commit('setLoginModalVisible', true)
                return
            }
            let _url = null
            let channelId = null
            switch (type) {
                case 1:
                    //  政策
                    // _url = "/page/index/newDetailsPolicy";
                    _url = "/other-policy/other-policy-detail"
                    channelId = 1
                    break;
                case 2:
                    //  通知
                    // _url = "/page/index/newDeclarationNotice";
                    _url = "/other-policy/other-declaration-detail"
                    channelId = 2
                    break;
                case 3:
                    //  公示
                    // _url = "/page/index/newNoticeDetails";
                    _url = "/other-policy/other-notice-detail"
                    channelId = 3
                    break;
            }
            this.$router.push({
                path: _url,
                query: {
                    id: pid,
                    // AreaCode: this.userLocation.areaCode,
                    channelId: channelId
                }
            });
        },
        onLoad() {
            this.finished = false;
            this.pageIndex = this.pageIndex + 1;
            this.GetList(true);
        },
        setSearchKey(name) {
            this.SearchKey = name;
            this.showSearchList = false;
            this.pageIndex = 1;
            this.finished = false;
            $("html,body").scrollTop(0);
            this.GetList();
        },
        async getSearchInfo() {
            const res = await GetByProjectInstantList({ SearchKey: this.SearchKey })
            if (res.success) {
                this.searchInfoList = res.Data || [];
                this.showSearchList = true;
            }
        },

        Search() {
            $("html,body").scrollTop(0);
            this.pageIndex = 1;
            this.finished = false;
            this.GetList();
        },
        onRefresh() {
            // 清空列表数据
            this.finished = false;

            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.pageIndex = 1;
            $("html,body").scrollTop(0);
            this.GetList(false, 1);
        }
    },
    created() {
        this.GetServiceSystem();
        let max = new Date().getFullYear();
        let min = 1900;
        for (let i = min; i <= max; i++) {
            this.columns.push(i);
        }
        this.columns.reverse();
        this.GetList();
    },
    mounted() {
        // this.$refs.userLocationRef.refreshLocation()
    }
};
</script>

<style lang="less" scoped>
@import "./PolicyList.css";

.more-level {
    padding: 10px;
}

.c-type {
    padding: 10px;
}

.more-title {
    font-size: 14px;
    margin-bottom: 4px;
}

.level-context,
.type-context {
    display: grid;
    grid-template-columns: repeat(4, 25%);
    font-size: 14px;
}

.level-context span,
.type-context span {
    font-size: 16px;
}

.search-box {
    display: flex;
    align-items: center;
    padding: 8px 10px;
    background: #FFF;
}

.searchInfoList {
    text-align: center;
    font-size: 14px;
    background-color: #FFF;

    .searchName {
        padding: 4px;
    }
}

.PolicyListB-l {
    display: flex;
    align-items: center;
}

.Label {
    line-height: 10px !important;
    margin-right: 5px !important;
}

/deep/ .van-search {
    width: 100%;
    background: #f7f8fa !important;
    font-size: .875rem;
}

/deep/ .van-field__body i {
    margin: 0 0 10px 0;
}
</style>
  