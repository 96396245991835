import axios from "axios";
import router from "@/router/index.js"
import store from '@/store/index.js'
import { Toast } from 'vant';

// 创建axios请求实例
const instance = axios.create({
    // baseURL: '',
    timeout: 20000
})

// 请求拦截器
instance.interceptors.request.use(
    function (config) {
        config.headers['Content-Type'] = 'application/json'
        config.headers.authorization = localStorage.getItem('loginToken')
        return config
    },
    function (error) {
        console.log(error)
    }
)

// 响应拦截器
instance.interceptors.response.use(
    function (response) {
        let res
        let success = null
        if (response.data.IsSuccess != undefined) {
            success = response.data.IsSuccess
        }
        if (response.data.isSuccess != undefined) {
            success = response.data.isSuccess
        }
        if (response.data.IsSuccess === true || response.data.isSuccess === true) {
            if (typeof response.data.data === 'object') {
                res = response.data.data
            }
            if (typeof response.data.data === 'string') {
                res = JSON.parse(response.data.data)
            }
            if (response.data.data === undefined) {
                if (typeof response.data.Data === 'object') {
                    res = response.data.Data
                }
                if (typeof response.data.Data === 'string' && response.data.Ver === 2) {
                    res = response.data.Data
                } else {
                    res = response.data.Data
                }
            }
            switch (response.data.Status + '') {
                case '200':
                    // 登录并且token有效
                    store.commit('setLoginState', true)
                    break;
                case '205':
                    Toast('请求成功,无数据返回');
                    break;
                case '103':
                    // 未登录
                    store.commit('setLoginState', false)
                    break;
                case '401':
                    Toast('无权限');
                    break
            }
            return { ...response.data, Data: res, success: success }
        } else if (response.data.IsSuccess === false || response.data.isSuccess === false) {
            switch (response.data.Status + '') {
                case '500':
                    Toast('服务器异常');
                    break;
            }
            return { ...response.data, Data: res, success: success }
        }
    },
    function (error) { console.log(error) }
)

const request = async ({ method, url, data, config }) => {
    switch (method) {
        case 'post':
            return instance.post(url, data, { ...config })
        case 'get':
            return instance.get(url, { params: data, ...config })
        case 'put':
            return instance.put(url, data, { ...config })
        case 'delete':
            return instance.delete(url, { params: data, ...config })
    }
}

export { request }