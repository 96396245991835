<template>
    <div class="box" v-track="{ triggerType: 'show', info: operateText.projectDetails }">
        <PageLoading :loading="pageLoading"></PageLoading>
        <!-- 标题头部 -->
        <div class="detail_info">
            <div class="detail">
                <div class="detail_top">
                    <span class="detail_name">{{ DetailsObj.ProjectTitle }}</span>
                </div>
                <div class="detail_des">
                    <div class="detail_user">
                        <span>受理部门：{{ DetailsObj.Department }}</span>
                        <span>{{ DetailsObj.DeclarationDesc }}</span>
                    </div>
                    <div class="detail_des_but">
                    </div>
                </div>
                <div class="HeadB-L">浏览量 {{ DetailsObj.Reads }}</div>
            </div>
        </div>
        <!--内容-->
        <div class="contentMain">
            <van-tabs v-model="tabActive" animated>
                <van-tab title="详情">
                    <div class="ArticleInformation PolicyDetailsMain detailsMain">
                        <p class="detailsMainTitle" v-if="!!DetailsObj.Support">扶持力度</p>
                        <p class="detailsMainText" v-if="!!DetailsObj.Support" v-html="setHtml(DetailsObj.Support)"></p>
                        <!-- <IIframe :srcdoc="DetailsObj.Support" :inside-style="contentStyle"/> -->
                        <p class="detailsMainTitle" v-if="!!DetailsObj.Condition">申报条件</p>
                        <!-- <IIframe :srcdoc="DetailsObj.Condition" :inside-style="contentStyle"/> -->
                        <p class="detailsMainText" v-if="!!DetailsObj.Condition" v-html="setHtml(DetailsObj.Condition)"></p>
                        <p class="detailsMainTitle" v-if="!!DetailsObj.Material">申报材料</p>
                        <!-- <IIframe :srcdoc="DetailsObj.Material" :inside-style="contentStyle"/> -->
                        <p class="detailsMainText" v-if="!!DetailsObj.Material" v-html="setHtml(DetailsObj.Material)"></p>
                        <p class="detailsMainTitle" v-if="!!DetailsObj.DeclareProcess">申报流程</p>
                        <!-- <IIframe :srcdoc="DetailsObj.DeclareProcess" :inside-style="contentStyle"/> -->
                        <p class="detailsMainText" v-if="!!DetailsObj.DeclareProcess"
                            v-html="setHtml(DetailsObj.DeclareProcess)"></p>
                    </div>
                    <div class="block" style="padding: 12px;" v-if="IsRelevantPublicitys">
                        <div class="detailsMainTitle">相关公示</div>
                        <PolicyCard v-for="item in aboutPublicitys" :key="item.PID" :policy="item" to-detail />
                    </div>
                </van-tab>
                <van-tab title="相关政策">
                    <div class="RelevantPolicies">
                        <!--列表-->
                        <div class="PolicyMain" v-if="IsRelevantPolicies">
                            <div v-for="item in RelevantPoliciesList" class="PolicyList a-active"
                                @click="ListLink(item.PID, item.ChannelId)" :key="item.PID">
                                <p class="PolicyListT">{{ item.Source }}</p>
                                <p class="PolicyTitle">{{ item.PolicyTitle }}</p>
                                <div class="PolicyListB">
                                    <div class="PolicyListB-l">
                                        <span class="Label">{{ item.ChannelName }}</span>
                                        {{ item.ReleaseDate }} 阅读{{ item.Reads }}
                                        <img src="/static/image/measure.png" v-if="item.IsAssessment" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--无数据提示-->
                        <div class="NoData" v-if="!IsRelevantPolicies">
                            <i class="weui-icon-warn weui-icon_msg-primary"></i>
                            <p>暂无相关政策</p>
                        </div>
                    </div>
                </van-tab>
                <van-tab title="相关通知">
                    <div class="RelevantPolicies">
                        <!--列表-->
                        <div class="PolicyMain" v-if="IsNoticePolicies">
                            <div v-for="item in NoticePoliciesList" class="PolicyList a-active"
                                @click="ListLink(item.PID, item.ChannelId)" :key="item.PID">
                                <p class="PolicyListT">{{ item.Source }}</p>
                                <p class="PolicyTitle">{{ item.PolicyTitle }}</p>
                                <div class="PolicyListB">
                                    <div class="PolicyListB-l">
                                        <span class="Label">{{ item.ChannelName }}</span>
                                        {{ item.ReleaseDate }} 阅读{{ item.Reads }}
                                        <img src="/static/image/measure.png" v-if="item.IsAssessment" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--无数据提示-->
                        <div class="NoData" v-if="!IsNoticePolicies">
                            <i class="weui-icon-warn weui-icon_msg-primary"></i>
                            <p>暂无相关通知</p>
                        </div>
                    </div>
                </van-tab>
            </van-tabs>
        </div>
    </div>
</template>
  
<script>
import PolicyCard from '@/components/policy-card';
import { GetProjectDetails, GetAboutList } from '@/api/project.js'
import PageLoading from '@/components/page-loading.vue'
import { pageOperate } from '@/appConfigData.js'
export default {
    components: {
        PolicyCard,
        PageLoading
    },
    data() {
        return {
            aboutPublicitys: [],
            IsRelevantPublicitys: true,
            pageLoading: true,
            TabIndex: 1,
            PID: null,
            DetailsObj: {},
            RelevantPoliciesList: [],
            IsRelevantPolicies: true,
            NoticePoliciesList: [],
            IsNoticePolicies: true,
            areaCode: null,
            tabActive: 0,
            hasPublicity: false,
            operateText: pageOperate,
            contentStyle: `
          body {
            font-size: 14px;
            color: #333;
            line-height: 1.5;
          }
        `
        };
    },
    created() {
        this.pageLoading = true
    },
    //  自定义方法
    methods: {
        setHtml(data) {
            let searchKey = this.$route.query.searchKey
            if (searchKey) data = data.replaceAll(searchKey, `<span style='color: #EA2D3F'>${searchKey}</span>`)
            return data
        },
        //详情获取
        async GetDetails() {
            const res = await GetProjectDetails({ pId: this.PID })
            if (res.success) {
                this.DetailsObj = res.Data || {};
                // this.pageLoading = false;
            }
        },
        async getAboutList(data, type) {
            const res = await GetAboutList({ ...data })
            if (res.success) {
                switch (type) {
                    case 'aboutPublicitys':
                        this.aboutPublicitys = res.Data
                        break;
                    case 'RelevantPoliciesList':
                        this.RelevantPoliciesList = res.Data
                        break;
                    case 'NoticePoliciesList':
                        this.NoticePoliciesList = res.Data
                        break;
                }
            }
        },
        //相关政策跳转
        ListLink(pid, type) {
            if (this.$store.state.loginState === false) {
                this.$store.commit('setLoginModalVisible', true)
                return
            }
            let _url = null
            let channelId = null
            switch (type) {
                case 1:
                    //  政策
                    // _url = "/page/index/newDetailsPolicy";
                    _url = "/other-policy/other-policy-detail"
                    channelId = 1
                    break;
                case 2:
                    //  通知
                    // _url = "/page/index/newDeclarationNotice";
                    _url = "/other-policy/other-declaration-detail"
                    channelId = 2
                    break;
                case 3:
                    //  公示
                    // _url = "/page/index/newNoticeDetails";
                    _url = "/other-policy/other-notice-detail"
                    channelId = 3
                    break;
            }
            this.$router.push({
                path: _url,
                query: {
                    id: pid,
                    channelId: channelId
                }
            });
        },
    },
    //挂载前状态
    beforeMount() {
        //获取pid
        this.PID = this.$route.query.id;
        this.areaCode = this.$route.query.areaCode;
    },
    //挂载结束状态
    mounted() {
        //详情获取
        Promise.all([
            this.GetDetails(),
            this.getAboutList({ pid: this.PID, area: this.areaCode, ChannelId: 1 }, 'aboutPublicitys'),
            this.getAboutList({ pid: this.PID, area: this.areaCode, ChannelId: 2 }, 'NoticePoliciesList'),
            this.getAboutList({ pid: this.PID, area: this.areaCode, ChannelId: 3 }, 'aboutPublicitys')
        ]).finally(() => {
            this.pageLoading = false
        })
    },
}
</script>
  
<style scoped lang="less">
/deep/ .van-tab--active {
    color: #df2b36;
    /* border-bottom: 2px solid #df2b36; */
}

/deep/ .Label {
    line-height: 10px;
    margin-right: 8px;
}

/deep/ .PolicyListB-l {
    display: flex;
    align-items: center;
}

.detail_info {
    width: 100%;
    height: auto;
    background-color: #F0EFF2;
    box-sizing: border-box;
    padding-bottom: 7px;
}

.detail {
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding: 13px 16px 5px 16px;
    box-sizing: border-box;
}

.detail_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 5px;
}

.detail_name {
    font-size: 17px;
    font-family: Alibaba;
    font-weight: bold;
    color: #333333;
    opacity: 1;
}

.detail_but {
    box-sizing: border-box;
    border: 1px solid #BF0711;
    padding: 5px 17px;
    font-size: 12px;
    color: #BF0711;
    border-radius: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.detail_counselorName {
    box-sizing: border-box;
    font-size: 11px;
    font-family: Alibaba;
    font-weight: 400;
    color: #333333;
    opacity: 1;
    border-bottom: 1px dotted #CCCCCC;
    padding-bottom: 11px;
    margin-bottom: 13px;
}

.detail_des {
    display: flex;
    justify-content: space-between;
}

.detail_user {
    box-sizing: border-box;
    padding-left: 7px;
    display: flex;
    flex-direction: column;
    font-size: 11px;
    font-family: Alibaba;
    font-weight: 400;
    color: #999999;
    opacity: 1;
    border-left: 3px solid #F8A441;
}

.detail_des_but {}

.box {
    padding-bottom: 70px;
}

.Head {
    background-color: #fff;
    padding: 12px;
    margin-bottom: 10px;
}

.HeadT {
    display: flex;
}

.Title {
    flex: 1;
    font-size: 17px;
    color: #333333;
    margin-bottom: 10px;
    font-weight: 700;
}

.Subscribe {
    font-size: 14px;
    color: #BF0711;
    border: 1px solid #BF0711;
    border-radius: 31px;
    background: #FFFFFF;
    font-size: 13px;
    width: 66px;
    height: 32px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Subscribed {
    width: 66px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #BF0711;
    border: 1px solid #BF0711;
    border-radius: 31px;
    background: #FFFFFF;
    font-size: 13px;
    box-sizing: border-box;
}

.HeadC {
    display: flex;
    align-items: center;
    height: 33px;
    border-left: 3px solid #ffa61a;
    padding-left: 10px;
    margin-bottom: 16px;
    color: #333333;
}

.HeadC .left {
    flex: 1;
}

.HeadC .left p {
    font-size: 12px;
}

.HeadC .right {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.HeadB {
    overflow: hidden;
}

.HeadB-L {
    font-size: 12px;
    color: #999999;
    line-height: 28px;
    margin-top: 5px;
}

.HeadB-R {
    float: right;
    font-size: 14px;
    color: #df2b36;
}


.TabSwitchBaes {
    flex: 1;
    position: relative;
    box-sizing: border-box;
    height: 100%;
    text-align: center;
    line-height: 45px;
    font-size: 15px;
    color: #333333;
    border-bottom: 1px solid #979797;
}

.Hot {
    position: absolute;
    width: 22px;
    height: 22px;
    right: 8px;
    top: 5px;
}

.detailsMain {
    background-color: #fff;
    padding: 12px;
}

.detailsMainTitle {
    font-size: 15px;
    color: #333333;
    font-weight: 700;
    margin-bottom: 5px;
}

.detailsMainText {
    font-size: 14px;
    color: #333333;
    margin-bottom: 15px;
}

/*服务列表*/
.serviceMain .serviceList {
    background-color: #fff;
    display: block;
    padding: 15px 12px;
    border-bottom: 1px solid #e7e7e7;
}

.serviceListT {
    display: flex;
    margin-bottom: 7px;
}

.serviceListTL {
    flex: 1;
    font-size: 14px;
    font-weight: 700;
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.serviceListTR {
    width: 93px;
    text-align: right;
    font-size: 16px;
    font-weight: 700;
    color: #df2b36;
}

.serviceListB {
    overflow: hidden;
    margin-bottom: 5px;
}

.serviceListBL {
    font-size: 11px;
    color: #666666;
}

.serviceListBR {
    width: 78px;
    height: 17px;
    background-image: linear-gradient(10deg, #f66e1e, #f89235);
    text-align: center;
    line-height: 17px;
    font-size: 11px;
    color: #ffffff;
}

.YellowColor {
    color: #f89235;
}

.serviceListFoot>span {
    display: inline-block;
    vertical-align: middle;
    -webkit-text-size-adjust: none;
    height: 16px;
    padding: 0 7px;
    line-height: 16px;
    font-size: 10px;
    color: #0088ff;
    background: #ecf6ff;
    margin-right: 5px;
}

/*宣传banner*/
.bannerImg {
    line-height: 0;
    background-color: #fff;
}

.bannerImg2 {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 680px;
    z-index: 66;
}

.bannerImg img {
    width: 100%;
}

/*非VIP*/
.noVip {
    height: 36px;
    line-height: 36px;
    background: #f8f8f8;
    padding: 0px 15px;
}

.noVip .img {
    margin-right: 10px;
}

.joinVip {
    border: 1px solid #e65954;
    color: #e54540;
    text-align: center;
    width: 75px;
    height: 32px;
    line-height: 32px;
    margin-top: 10px;
}

.clear {
    clear: both;
}

.serviceListTR .search {
    padding: 3px 10px;
    background: #F4F4F4;
    color: #E54540;
    font-size: 10px;
    font-weight: 400;
}
</style>
  