const proApi = {
  policypro: "https://apis.policypro.cn",
  tentapi: "https://entapi.10nnet.com",
};

const preApi = {
  policypro: "http://t-apis.policypro.cn",
  tentapi: "http://tentapi.10nnet.com",
};

const devApi = {
  policypro: "http://t-apis.policypro.cn",
  tentapi: "http://t-apis.policypro.cn",
};


let hosts = devApi;
let env = process.env.NODE_ENV;
console.log("process", process.env)

if (env === "production") hosts = proApi;
else if (env === "pre") hosts = preApi;
else if (env === "development") hosts = devApi;
else hosts = devApi;

module.exports = {
  host: hosts,
  prefix: {
    api: "/api",
  },
  serviceApi: {
    policyLogin: "/v1/Account/PolicyLogin",
    getProjectList: "/v2/Policy/GetProjectList",
    getDictionary: "/v1/WebPolicy/GetDictionary",
    getProjectCategory: "/v1/Platform/ProjectCategory/GetPageList",
    getProjectDetails: "/v1/WebDeclareProject/GetWebDetails",
    getAboutList: "/v2/Platform/Policy/GetAboutPolicyList",
    getAdoptDetails: "/v1/WebPolicy/GetAdoptDetails",
    getAboutProjectList: "/v2/WebDeclareProject/GetAboutProjectList",
    getPolicyAttachmentList: "/v1/WebPolicy/GetPolicyAttachmentList",
    getCollection: "/v1/WebCollection/GetCollection",
    getAboutInfoList: "/v2/WebPolicy/GetAboutInfoList",
    getPolicyList: "/v2/Policy/GetPolicyList",
    getByProjectInstantList: "/v1/WebDeclareProject/GetByProjectInstantList",
    smartMatchSaveAnswer: "/v1/Policy/SmartMatchSaveAnswer",
    getSmartMatchList: "/v1/policy/GetSmartMatchList?evaluationId=",
    createNewSmartMatch: "/v1/Policy/CreateNewSmartMatch",
    getEditEvaluationResultDoc: "/v1/Policy/GetEditEvaluationResultDoc",
    getQuestionList: "/v2/WebSmartMatch/GetQuestionList",
    getRelease: "/v1/WebPolicy/GetRelease",
    searchRelationList: "/v2/WebSmartMatch/SearchRelationList",
    getUserInfo: "/v1/WebUser/GetUserInfo",
    getGroupIndustryFirstLevel: "/v1/WebPolicy/GetGroupIndustryFirstLevel",
    getHomeProjectList: "/Home/GetProjectList",
    addOperationLog: '/v1/WebCommon/AddOperationLog',
    //快捷登录
    phoneLoginOfSpecific: '/v1/PolicyUser/PhoneLoginOfSpecific',
  },
};
