<template>
    <div class="token-login">
        <van-loading size="30px" color="#df2b36">登录中...</van-loading>
    </div>
</template>
  
<script>
import { tokenLogin } from '@/api/login.js'
export default {
    methods: {
        async otherLogin(token) {
            const { back, id, channelId, source } = this.$route.query;
            const res = await tokenLogin({ token: token.replaceAll(" ", "+") })
            if (res.success) {
                localStorage.setItem("loginToken", res.Data);
                this.$store.commit('setLoginState', true)
                setTimeout(() => {
                    if (back) {
                        if (id && channelId) {
                            return this.$router.replace(back + "?id=" + id + "&channelId=" + channelId).catch(() => { });
                        } else if (id) {
                            return this.$router.replace(back + "?id=" + id).catch(() => { });
                        } else if (source) {
                            return this.$router.replace(back + "?source=" + source).catch(() => { });
                        } else {
                            return this.$router.replace(back).catch(() => { });
                        }
                    }
                }, 800);
            }
        }
    },
    created() {
        const { token, type } = this.$route.query;
        if (token && token.length > 5) {
            // 第三方对接跳转地址
            if (type == 'pub') {
                this.otherLogin(token)
            }
        } else {
            localStorage.clear()
            this.$router.push("/").catch(() => { });
        }
    },
};
</script>
  
<style>
.token-login {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>