<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
};
</script>

<style lang="less">
@import "./style/main.css";

@font-face {
  font-family: Alibaba;
  src: url(https://static.10nservice.com/pub/web/Alibaba_PuHuiTi_2.0_55_Regular_55_Regular.a22ad467.ttf)
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Alibaba;
  // font-size: 16px;
}

::-webkit-scrollbar {
  // display: none;
}

/* 滚动条 */
::-webkit-scrollbar-thumb:horizontal {
  /*水平滚动条的样式*/
  width: 0px;
  background-color: #cccccc;
  border-radius: 6px;
}

::-webkit-scrollbar-track-piece {
  // background-color: #fff; /*滚动条的背景颜色*/
  border-radius: 0;
  /*滚动条的圆角宽度*/
}

::-webkit-scrollbar {
  width: 0px;
  /*滚动条的宽度*/
  height: 6px;
  /*滚动条的高度*/
}

::-webkit-scrollbar-thumb:vertical {
  /*垂直滚动条的样式*/
  height: 0px;
  background-color: #cccccc;
  border-radius: 4px;
  outline-offset: -2px;
}

::-webkit-scrollbar-thumb:hover {
  /*滚动条的hover样式*/
  height: 0px;
  background-color: #9f9f9f;
  border-radius: 4px;
}
</style>