<template>
  <div class='matched-projects'>
    <slot></slot>
    <div class="projects">
      <div v-show="innerProjectList.length">
        <Projects :projectData="limitProjects" :deleleable="false" @on-del-project="onDelProject" />
      </div>
      <van-empty v-show="!innerProjectList.length" description="暂时找不到相关项目" />
    </div>
  </div>
</template>

<script>
import Projects from "@/components/projects";
import { GetDictionary, GetRelease } from '@/api/base.js'
import { SearchRelationList } from '@/api/matchProject.js'
import $ from 'jquery'
export default {
  components: {
    Projects
  },
  props: {
    projectList: Array,
    evaluationId: {
      type: String,
      required: true
    }
  },
  watch: {
    projectList(val) {
      this.innerProjectList = val || [];
    }
  },
  computed: {
    limitProjects() {
      return this.innerProjectList;
    }
  },
  data() {
    return {
      projectIDs: [],
      isShowBindPhone: false,
      innerProjectList: [],
      searchForm: {
        searchKey: '',
        classId: '',
        gradeId: '',
        releaseIds: '',
        months: '',
        Weight: '',
      },
      Weightops: [
        { code: "", name: "星级" },
        { code: 1, name: "一星" },
        { code: 2, name: "二星" },
        { code: 3, name: "三星" },
        { code: 4, name: "四星" },
        { code: 5, name: "五星" },
      ],
      monthOps: [
        { code: "", name: "月份" },
        { code: 1, name: "1月" },
        { code: 2, name: "2月" },
        { code: 3, name: "3月" },
        { code: 4, name: "4月" },
        { code: 5, name: "5月" },
        { code: 6, name: "6月" },
        { code: 7, name: "7月" },
        { code: 8, name: "8月" },
        { code: 9, name: "9月" },
        { code: 10, name: "10月" },
        { code: 11, name: "11月" },
        { code: 12, name: "12月" }
      ],
      gradeOps: [{ code: "", name: "级别" }],
      classOps: [{ code: "", name: "类别" }],
      releaseOps: [{ code: "", name: "系统" }]
    }
  },
  created() {
    this.init();
    Object.keys(this.searchForm)
      .filter(item => !['searchKey'].includes(item))
      .forEach(item => {
        this.$watch(vm => vm.searchForm[item], (val) => {
          this.searchRelationList();
        }, {
          deep: true
        })
      })
  },
  methods: {
    init() {
      return Promise.all([
        this.getGradeOps(),
        this.getClassOps(),
        this.getReleaseOps()
      ]).finally(() => {
        this.$emit('on-finally');
      })
    },
    async searchRelationList() {
      const res = await SearchRelationList({
        ...this.searchForm,
        evaluationId: this.evaluationId,
        ProjectIDs: this.projectIDs.length ? this.projectIDs.join(',') : null
      })
      if (res) {
        this.innerProjectList = res.Data || [];
        this.$emit('on-search', this.innerProjectList)
        $("html, body").scrollTop($('#search').offset().top);
      }
    },
    async getGradeOps() {
      const res = await GetDictionary({ rootid: 10640, row: 50 })
      if (res) {
        this.gradeOps.push(...(res.Data || []));
      }
    },
    async getClassOps() {
      const res = await GetDictionary({ rootid: 10901, row: 50 })
      if (res) {
        this.classOps.push(...(res.Data || []));
      }
    },
    async getReleaseOps() {
      const res = await GetRelease({})
      if (res) {
        this.releaseOps.push(...(res.Data || []));
      }
    },
    onDelProject(index, project) {
      // this.projectList.splice(index, 1);
      this.projectIDs.push(project.PID)
      this.searchRelationList()
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .select-wrapper {
  flex: 1;
  margin-right: 6px;
}

/deep/ .select-wrapper:last-child {
  margin-right: 0;
}

/deep/ .select-wrapper select {
  flex: 1;
  font-size: 12px;
  color: #666;
}
</style>

<style scoped lang="less">
.row {
  display: flex;
  justify-content: space-between;
  padding: 4px 10px;
}

.search-c {
  padding-bottom: 10px;
  background: #fff;
}

.projects {
  background: #fff;

  .tips {
    padding: 20px 0;
  }
}

.limit-tips {
  font-size: 12px;
  color: #e2383b;
  text-align: center;
}

.surplus-tips {
  margin: 10px 0;
  border-color: #ddd;

  &:after {
    border-width: 1px;
  }

  &::before {
    border-width: 1px;
  }
}
</style>
