import { request } from '../http/request'
import config from '@/serviceConfig/service'
const { host, prefix, serviceApi } = config
const { policypro } = host
const { api } = prefix
const { smartMatchSaveAnswer, getSmartMatchList, createNewSmartMatch, getEditEvaluationResultDoc, getQuestionList, searchRelationList, getGroupIndustryFirstLevel } = serviceApi

export const SmartMatchSaveAnswer = (data) => {
    return request({
        url: policypro + smartMatchSaveAnswer,
        method: 'post',
        data,
    })
}

export const GetSmartMatchList = (data) => {
    return request({
        url: policypro + getSmartMatchList + data.evaluationId,
        method: 'post',
        data: {},
    })
}

export const CreateNewSmartMatch = (data) => {
    return request({
        url: policypro + createNewSmartMatch,
        method: 'post',
        data,
    })
}

export const GetEditEvaluationResultDoc = (data) => {
    return request({
        url: policypro + getEditEvaluationResultDoc,
        method: 'post',
        data,
    })
}

export const GetQuestionList = (data) => {
    return request({
        url: policypro + api + getQuestionList,
        method: 'get',
        data,
    })
}
// searchRelationList
export const SearchRelationList = (data) => {
    return request({
        url: policypro + api + searchRelationList,
        method: 'get',
        data,
    })
}

export const GetGroupIndustryFirstLevel = (data) => {
    return request({
        url: policypro + api + getGroupIndustryFirstLevel,
        method: 'get',
        data,
    })
}