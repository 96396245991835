<template>
  <div class="download-btn">
    <button @click="downloadPlanReport">
      下载政策申报指引报告
    </button>
    <van-popup v-model="tipVisible" class="popup">
      <h3 class="title">下载失败</h3>
      <div class="content">
        <div class="sub-title">将该企业加入客户池后</div>
        <div class="message">即可<b>下载政策申报指引报告</b></div>
      </div>
      <div class="btn" @click="tipVisible = false">知道了</div>
      <div class="footer">
        当前客户池：<b>{{ currentCount }}</b>/{{ maxCount }}
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  props: {
    companyId: {
      type: String,
    },
  },
  data() {
    return {
      tipVisible: false,
      currentCount: 0,
      maxCount: 0,
      IsExist: true,
    };
  },
  mounted() {
  },
  methods: {
    async downloadPlanReport() {
      this.$emit("on-download");
    },
  },
};
</script>

<style scoped lang="less">
.download-btn {
  flex: 65;

  button {
    width: 100%;
    padding: 8px 0;
    box-sizing: border-box;
    border-radius: 50px;
    color: #fff;
    background: var(--primary);
    border: none;
  }
}

.popup {
  width: calc(100% - 70px);
  padding: 0 20px;
  box-sizing: border-box;
  border-radius: 10px;
  text-align: center;
  overflow: unset;

  .title {
    width: 183px;
    height: 40px;
    line-height: 40px;
    margin: 0 auto;
    color: #fff;
    margin-top: -3px;
    font-size: 19px;
    background: url("~@/static/image/icon_znpp_tcbq.png") no-repeat;
    background-size: cover;
  }

  .content {
    margin: 45px 0 37px 0;

    .sub-title {
      font-size: 17px;
      font-weight: bold;
      margin-bottom: 11px;
    }

    .message {
      font-size: 14px;
      font-weight: bold;
      color: var(--primary);

      b {
        color: var(--primary);
      }
    }
  }

  .btn {
    width: 143px;
    height: 36px;
    font-size: 14px;
    margin: 0 auto;
    margin-bottom: 8px;
    text-align: center;
    line-height: 36px;
    border-radius: 50px;
    color: #fff;
    background: rgb(182, 51, 51);
  }

  .footer {
    line-height: 1;
    margin-bottom: 12px;
    font-size: 13px;
    color: #8f8f8f;

    b {
      color: var(--primary);
    }
  }
}

.exist {
  background-color: #ccc !important;
}
</style>
