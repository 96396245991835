<template>
  <div class="industry-select">
    <div v-for="(item, prop) in industryData" :key="item.code">
      <div class="groupName">
        <div></div>
        {{ prop }}
      </div>
      <div class="box_type">
        <div class="type_Item" v-for="(item2, index2) in item" :key="index2" @click="setClick(prop, item2)"
          :style="(index2 + 1) % 3 == 0 ? { marginRight: '0px', float: 'right' } : ''"
          :class="item2.Selected ? 'itemAcitve' : ''">
          <div style="width:96%;text-align:center;margin:0px auto;">{{ item2.name }}</div>
        </div>
        <div class="clear"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetGroupIndustryFirstLevel } from '@/api/matchProject.js'
export default {
  props: {
    companyId: {
      type: String
    },
    evaluationId: {
      type: String
    },
    radio: {
      type: Boolean
    },
    maxSelect: {
      type: Number,
      default: 5
    }
  },
  data() {
    return {
      industryData: {}
    }
  },
  created() {
    this.getIndustryFirstLevel();
  },
  methods: {
    setClick(parentProp, industry) {
      if (this.radio) {
        this.onSingleChioce(industry);
      } else {
        this.onMutipleChioce(parentProp, industry);
      }
    },
    onSingleChioce(industry) {
      Object.values(this.industryData).forEach(list => {
        list.forEach(item => item.Selected = false)
      })
      industry.Selected = true;
    },
    onMutipleChioce(parentProp, industry) {
      const info = this.industryData[parentProp].find(item => item.code == industry.code);
      let selectCount = 0;
      Object.values(this.industryData).forEach(list => {
        selectCount += list.filter(item => item.Selected).length;
      });
      if (selectCount >= this.maxSelect && !info.Selected) {
        return this.$toast(`最多选择${this.maxSelect}个产业`);
      }
      info.Selected = !info.Selected;
      this.$emit('on-select', this.getSelectedIndustrys());
    },
    async getIndustryFirstLevel() {
      const res = await GetGroupIndustryFirstLevel({
        enterpriseId: this.companyId,
        evaluationId: this.evaluationId
      })
      if (res) {
        const data = res.Data || [];
        // 如果是单选，如果有推荐产业则自选中第一个
        if (this.radio) {
          let hasSelected = false;
          Object.values(data).forEach(list => {
            list.forEach(item => {
              // 有已经选中的，其他全部取消选中，以达到只选中第一个推荐产业的效果
              if (hasSelected) {
                item.Selected = false;
              }
              if (item.Selected) {
                hasSelected = true;
              }
            })
          })
        }
        this.industryData = data;
      } else {

      }
    },
    // 供外部获取选中产业
    getSelectedIndustrys() {
      const result = [];
      Object.values(this.industryData).forEach(list => {
        result.push(...list.filter(item => item.Selected));
      })
      return result;
    }
  }
}
</script>

<style lang="less" scoped>
.industry-select {
  padding: 10px;
  padding-top: 0;
  background: #fff;
  display: flex;
  flex-direction: column;
}

.groupName {
  height: 30px;
  line-height: 30px;
  font-size: 10px;
  color: #8b8b8b;
  display: flex;
}

.groupName div {
  width: 6px;
  height: 6px;
  background: var(--primary);
  border-radius: 50%;
  margin: 11px 5px 0px 0px;
}

.box_type {
  width: 100%;
  height: auto;
}

.type_Item {
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32%;
  background: #f8f8f8;
  font-size: 14px;
  color: #333333;
  margin-bottom: 5px;
  margin-right: 2%;
  text-align: center;
  float: left;
  background: #f8f8f8;
}

.itemAcitve {
  background: var(--primary);
  color: #ffffff;
}
</style>