import Header from './header';
import MatchedProjects from './matched-projects';
import DownloadPlanReport from './download-plan-report';
import { CreateNewSmartMatch, GetEditEvaluationResultDoc } from '@/api/matchProject.js'
export default {
  components: {
    Header,
    MatchedProjects,
    DownloadPlanReport
  },
  data() {
    return {
      pageLoading: true,
      isShowBindPhone: false,
      evaluationId: '',
      matchResult: {},
      matchedProjects: [],
      matchedProjectCount: 0,
      maxLength: 0,
      //绑定手机号码的后续操作（1：绑定以查看更多项目，不做任何操作；2：下载全年规划报告前的绑定，绑定后跳转到规划报告页面）
      bindPhoneAfterOperate: '',
      companyId: '',
      searchDataList: [],
      isSearch: false
    }
  },
  created() {
    this.createNewMatch()
  },
  computed: {
    userInfo() {
      return {};
    }
  },
  watch: {
    matchedProjects(val) {
      this.matchedProjectCount = val.length
    }
  },
  methods: {
    async createNewMatch() {
      this.pageLoading = true;
      const res = await CreateNewSmartMatch({})
      if (res) {
        this.evaluationId = res.Data.EvaluatioId;
        this.companyId = res.Data.CompanyId;
        this.matchResult = res.Data
        this.matchedProjects = res.Data.List
        this.matchedProjectCount = this.matchedProjects.length;
        this.maxLength = this.matchedProjects.length;
        this.pageLoading = false
      } else {
        this.pageLoading = false
      }
    },
    onSearch(data) {
      this.searchDataList = data
      this.isSearch = true
    },
    async downloadPlanReport() {
      if (this.$store.state.loginState === false) {
        this.$store.commit('setLoginModalVisible', true)
        return
      }
      const res = await GetEditEvaluationResultDoc({ evaluationId: this.evaluationId })
      localStorage.setItem("serveReport_curPlanReportPush", JSON.stringify(res.Data));
      this.$router.push({
        path: "/other-planning-report",
        query: {
          source: "serveReport",
          // gradeCode: this.userInfo.GradeCode
        }
      });
    },
    toPerfectInfo() {
      this.$router.push({
        path: '/other-match-project/other-perfect-info',
        query: {
          evaluationId: this.evaluationId,
          companyId: this.matchResult.CompanyId,
          companyName: this.matchResult.Enterprise
        }
      })
    },
  }
}
