// 防抖
export function _debounce(fn, delay = 500) {
    let timer = null
    return function () {
        let _this = this
        let args = arguments
        if (timer) clearTimeout(timer)
        timer = setTimeout(function () {
            fn.apply(_this, args)
        }, delay)
    }
}

export const removeEmptyValues = (obj) => {
    let result = {};
    Object.keys(obj).filter(key => {
        let value = obj[key];
        if (value !== null && value !== '' && !Array.isArray(value) && (typeof value !== 'object' || Object.keys(value).length === 0)) {
            result[key] = value;
        }
    });
    return result;
}

export function objArrJoin(arr, fieid) {
    if (!Array.isArray(arr) || typeof (fieid) != 'string') return '';
    let str = arr.reduce((str, item) => str + (item[fieid] !== undefined ? item[fieid] : '') + ',', '');
    str = str.substr(0, str.length - 1);
    return str;
}

export function isEmpty(val) {
    return val === '' || val === null || val === undefined;
}

export function safeParse(str) {
    if (typeof (str) === 'string') {
        return JSON.parse(str);
    }
    return str;
}