<template>
    <div class="project-list" v-track="{ triggerType: 'show', info: operateText.projectList }">
        <van-sticky>
            <div class="top">
                <UserLocation @on-location="onLocationChange" ref="userLocationRef" />
                <van-search shape="round" placeholder="请输入搜索关键词" @search="search" v-model="searchForm.SearchKey" />
            </div>
            <van-dropdown-menu>
                <van-dropdown-item :options="monthOps" v-model="searchForm.months" @change="search" />
                <van-dropdown-item :options="categoryOps" v-model="searchForm.classID" @change="search" />
                <van-dropdown-item :options="systemOps" v-model="searchForm.ReleaseIds" @change="search" />
                <van-dropdown-item title="更多筛选" class="more-filter" ref="moreFilter">
                    <div class="item">
                        <div class="title">级别</div>
                        <ul class="ops">
                            <li v-for="(item, index) in levelOps" :key="index" :class="[
                                'option',
                                { active: item.value === searchForm.gradeID },
                            ]" @click="(searchForm.gradeID = item.value), search()">
                                {{ item.text }}
                            </li>
                        </ul>
                    </div>
                    <div class="item">
                        <div class="title">重点领域</div>
                        <ul class="ops">
                            <li v-for="(item, index) in focusAreaOps" :key="index" :class="[
                                'option',
                                { active: item.value === searchForm.CategoryName },
                            ]" @click="(searchForm.CategoryName = item.value)">
                                {{ item.text }}
                            </li>
                        </ul>
                    </div>
                    <div class="bottom">
                        <van-button color="#df2b36" class="close-btn"
                            @click="$refs.moreFilter.toggle(false)">关闭</van-button>
                    </div>
                </van-dropdown-item>
            </van-dropdown-menu>
            <div class="search-text" v-if="searchText">
                <span class="text">{{ searchText }}</span>
                <van-icon name="clear" class="icon" @click="clearSearchCond" />
            </div>
            <div class="total">共{{ total }}个项目</div>
        </van-sticky>
        <PageList :getListFn="getProjects" ref="projectList" emptyText="暂时找不到相关项目" class="projects" :page-size="20">
            <template slot-scope="slotProps">
                <Projects :projectData="slotProps.list" :searchKey=searchForm.SearchKey />
            </template>
        </PageList>
    </div>
</template>
  
<script>
import Projects from "@/components/projects.vue";
import PageList from "@/components/page-list.vue"
import { GetProjectList } from "@/api/project.js"
import { GetDictionary, GetProjectCategory } from "@/api/base.js"
import UserLocation from '@/components/user-location';
import { pageOperate } from '@/appConfigData.js'
export default {
    components: {
        Projects,
        PageList,
        UserLocation
    },
    data() {
        return {
            searchCond: [],
            total: null,
            categoryOps: [],
            systemOps: [],
            levelOps: [],
            focusAreaOps: [],
            searchForm: {
                SearchKey: "",
                months: "",
                classID: "",
                ReleaseIds: "",
                gradeID: "",
                CategoryName: "",
            },
            areaCode: '',
            monthOps: [
                { value: "", text: "月份" },
                { value: 1, text: "1月" },
                { value: 2, text: "2月" },
                { value: 3, text: "3月" },
                { value: 4, text: "4月" },
                { value: 5, text: "5月" },
                { value: 6, text: "6月" },
                { value: 7, text: "7月" },
                { value: 8, text: "8月" },
                { value: 9, text: "9月" },
                { value: 10, text: "10月" },
                { value: 11, text: "11月" },
                { value: 12, text: "12月" },
            ],
            operateText: pageOperate,
        }
    },
    created() {
        this.getDictionary({ type: 'categoryOps', name: '类别', mapKey: [], parmas: { rootid: 10901, row: 50 } })
        this.getDictionary({ type: 'systemOps', name: '系统', mapKey: [], parmas: { rootid: 10624, row: 50 } })
        this.getDictionary({ type: 'levelOps', name: '', mapKey: [], parmas: { rootid: 10640, row: 50 } })
        this.getProjectCategory({ type: 'focusAreaOps', name: '', mapKey: ['CategoryName'], parmas: { pageIndex: 1, isShow: "true" } })
    },
    activated() {
        const { CategoryName } = this.$route.params;
        if (CategoryName) {
            this.searchForm.CategoryName = CategoryName;
        }
    },
    watch: {
        "searchForm.gradeID"(val) {
            const selLevel = this.levelOps.find((item) => item.value == val) || {};
            this.$set(this.searchCond, 0, val ? `级别：${selLevel.text}` : "");
            this.search();
        },
        "searchForm.CategoryName"(val) {
            this.$set(this.searchCond, 1, val ? `重点领域：${val}` : "");
            this.search();
        },
    },
    computed: {
        searchText() {
            return this.searchCond.filter((item) => item).join("；");
        }
    },
    mounted() {
        // this.$refs.userLocationRef.refreshLocation()
    },
    methods: {
        search() {
            this.$nextTick(() => {
                this.$refs.projectList.refresh();
            });
        },
        clearSearchCond() {
            this.searchForm.gradeID = "";
            this.searchForm.CategoryName = "";
        },
        onLocationChange(area) {
            this.areaCode = area.areaCode;
            this.search();
        },
        async getDictionary(data) {
            const res = await GetDictionary({ ...data.parmas })
            if (res.success) {
                this.getOps(res.Data, data.type, data.name, data.mapKey)
            }
        },
        async getProjectCategory(data) {
            const res = await GetProjectCategory({ ...data.parmas })
            if (res.success) {
                this.getOps(res.Data, data.type, data.name, data.mapKey)
            }
        },
        getOps(reqOps, field, allText, mapKey = []) {
            const ret = (reqOps || []).map((item) => {
                return {
                    text: item[mapKey[0] || "name"],
                    value: item[mapKey[1] || "code"] || item[mapKey[0] || "name"],
                };
            });
            ret.unshift({ text: allText || "不限", value: "" });
            this[field] = ret;
        },
        getProjects(params) {
            return new Promise(async (resolve, reject) => {
                let res = await GetProjectList({
                    ...params,
                    ...this.searchForm,
                    AreaCode: this.areaCode
                })
                this.total = res.RecordCount;
                resolve(res)
            })
        }
    }
}
</script>
  
<style scoped lang="less">
.project-list {
    position: relative;

    .vip-bottom {
        position: absolute;
        top: 400px;
        width: 100%;
    }
}

.top {
    display: flex;
    align-items: center;
    padding: 8px 10px;
    background: #fff;

    .van-search {
        flex: 1;
        padding: 0;
        margin-left: 4px;
    }
}

.more-filter {
    .item {
        padding: 20px;
        border-bottom: 1px solid #eee;

        .title {
            font-size: 12px;
            color: #8f8f8f;
        }

        .ops {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: -6px;

            .option {
                margin-bottom: 6px;
                padding: 5px 8px;
                border-radius: 2px;
                margin-right: 10px;
                font-size: 12px;
                background: #f4f4f4;

                &.active {
                    color: #fff;
                    background: #08f;
                }
            }
        }
    }

    .bottom {
        text-align: center;
    }

    .close-btn {
        margin: 10px 0;
        padding: 0 40px;
    }
}

.search-text {
    display: flex;
    align-items: center;
    padding: 6px 12px;
    background: #fff;

    .text {
        flex: 1;
        color: var(--primary);
        font-size: .75rem;
    }

    .icon {
        font-size: 14px;
        color: #aaa;
    }
}

.total {
    font-size: 12px;
    padding: 0 10px;
    line-height: 30px;
    color: #ffa61a;
    background: #f8f8f8;
}

.projects {
    padding-bottom: 80px;
}

.vip-tips {
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 10px;
    border-radius: 100px;
    color: rgb(187, 97, 36);
    background: rgb(255, 248, 229);

    .icon {
        margin: 0 10px;
    }

    .center {
        flex: 1;

        .title {
            font-size: 14px;
        }

        .desc {
            font-size: 12px;
        }
    }

    .right {
        display: flex;
        align-items: center;
        font-size: 12px;
    }
}

/deep/ .van-swipe-cell__right {
    right: -1px;
}
</style>