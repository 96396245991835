import VueRouter from "vue-router";
import HomePage from '@/pages/homePage/homePage.vue';
import tokenLogin from '@/pages/tokenLogin/index.vue';
import baseLayout from '@/pages/baseLayout/index.vue';
import OtherProject from '@/pages/other-project/index.vue'
import OtherProjectDetail from '@/pages/other-project-detail/index.vue'
import OtherPolicyDetail from '@/pages/other-policy-detail/index.vue'
import OtherPolicy from '@/pages/other-policy/index.vue'
import OtherPlanningReport from '@/pages/other-match-project/planning-report.vue'
import OtherMatchProject from '@/pages/other-match-project/index.vue'
import OtherPerfectInfo from '@/pages/other-match-project/perfect-info.vue'
import QuickLogin from '@/pages/quickLogin/index.vue'
const router = new VueRouter({
    routes: [
        {
            path: '/',
            component: baseLayout,
            children: [
                {
                    path: '/homePage',
                    component: HomePage
                },
                {
                    path: '/other-project',
                    component: OtherProject
                },
                {
                    path: '/other-project-detail',
                    component: OtherProjectDetail
                },
                {
                    path: '/other-policy/other-policy-detail',
                    component: OtherPolicyDetail,
                },
                {
                    path: '/other-policy/other-declaration-detail',
                    component: OtherPolicyDetail,
                },
                {
                    path: '/other-policy/other-notice-detail',
                    component: OtherPolicyDetail,
                },
                {
                    path: '/other-policy',
                    component: OtherPolicy
                },
                {
                    path: '/other-planning-report',
                    name: 'other-planning-report',
                    component: OtherPlanningReport
                },
                {
                    path: '/other-speed-match',
                    name: 'other-match-project',
                    component: OtherMatchProject
                },
                {
                    path: '/other-match-project/other-perfect-info',
                    name: 'other-perfect-info',
                    component: OtherPerfectInfo
                },
            ]
        },
        {
            path: '/token-login',
            component: tokenLogin
        },
        {
            path: `/${window._ORG_INFO_.login_path || 'ZZmj4aCAQQFrYOr'}`,
            component: QuickLogin
        },
    ],
    mode: 'history'
})

router.beforeEach((to, from, next) => {
    if (to.path === '/') {
        next('/homePage')
    } else {
        next()
    }

})


export default router