<template>
  <div class='header'>
    <div class="info">
      <h3 class="name" @click="toCompanyDetail">{{ $route.query.companyName || data.Enterprise }}</h3>
      <!-- <p class="min-txt line">最后一次更新时间: {{ RemoveTime(data.LastUpdateTime) }}</p> -->
      <!-- <div class="look-publicity" @click="lookPublicity">查看历年公示</div> -->
    </div>
    <div class="bottom">
    </div>
    <div class="icon">
      <img src="@/static/image/yx.png" width="100%" alt />
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      isShowSearchCompany: false,
      companyName: '',
      myEnterprise: [],
      IsExist: false
    }
  },
  props: {
    data: Object
  },
  created() { },
  methods: {
    toCompanyDetail() {
      const { id, companyName } = this.$route.query;
      this.$router.push({
        path: '/match-project/company-info',
        query: {
          name: this.data.Enterprise || companyName,
          id: this.data.CompanyId,
          evaluationId: id
        }
      })
    },
  }
}
</script>

<style scoped lang="less">
.header {
  position: relative;
  background: linear-gradient(315deg, rgba(255, 252, 249, 1) 0%, rgba(255, 237, 229, 1) 100%);

  .info {
    padding: 15px 10px;
    margin-right: 20px;
  }

  .name {
    display: flex;
    align-items: center;
    color: #08f;
    font-size: 14px;
  }

  .line {
    border-bottom: 3px solid #EB644B;
    padding-bottom: 4px;
    margin-bottom: 4px;
  }

  .icon {
    position: absolute;
    top: 0;
    right: 0;
    width: 70px;
  }

  .bottom {
    padding: 0 10px 10px 10px;
    display: flex;

    .btn {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      line-height: 1;
      box-sizing: border-box;
      font-size: 14px;
      border-radius: 50px;
      color: #fff;
      background: #DA6C55;
      text-align: center;

      &+.btn {
        margin-left: 8px;
      }

      &.btn-plain {
        color: #DA6C55;
        border: 1px solid #DA6C55;
        background: none;
      }

      .txt {
        margin-left: 4px;
      }

      .arrow {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        margin-left: 4px;
        border-radius: 50%;
        color: #fff;
        background: #DA6C55;
      }
    }
  }
}

.btn-active {
  white-space: nowrap;
  padding: 0 4px;
  flex: .4 !important;
}
</style>
