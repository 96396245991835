<template>
    <div class="policy-detail" v-track="{ triggerType: 'show', info: operateText.policyDetails }">
        <PageLoading :loading="pageLoading" />
        <div class="head">
            <div class="title">{{ policyDetail.PolicyTitle }}</div>
            <div class="bottom">
                <div class="left">
                    <span>收藏{{ policyDetail.CollectCount }} 阅读{{ policyDetail.Reads }}</span>
                    <span class="sign">惠企政策</span>
                </div>
                <div class="collection">
                </div>
            </div>
        </div>
        <van-tabs class="tabs" v-model="tabVal" sticky>
            <van-tab :title="currentTabName" name="file-detail">
                <div class="policy-main">
                    <div class="info">
                        <div class="info-item">发文单位：{{ policyDetail.Source }}</div>
                        <div class="info-item">发文时间：{{ policyDetail.ReleaseDate }}</div>
                    </div>
                    <div id="policy-privew" v-html="policyDetail.PolicyText" @click="onClickPolicyPrivew"></div>
                    <div class="view-source" @click="viewSource">查看政府原文</div>
                    <div v-if="attachList.length" class="attach">
                        <div class="AttachmentPathTitle" style="font-size: 15px">附件：</div>
                        <div v-for="item in attachList" :key="item.PID" class="attach-item">
                            <!-- :href="initFileUrl(item.AttachmentPath)" :download="item.AttachmentTitle" -->
                            <span class="downText" @click="downloaded(item.AttachmentPath)">{{ item.AttachmentTitle
                            }}</span>
                        </div>
                    </div>
                </div>
                <div class="block" v-if="aboutProjects.length">
                    <div class="block-title">相关项目</div>
                    <ProjectCard v-for="item in aboutProjects" :key="item.PID" :project="item" to-detail />
                </div>
            </van-tab>
            <van-tab title="相关政策" name="policy" v-if="typeId != 1">
                <template v-if="aboutPolicys.length">
                    <PolicyCard v-for="item in aboutPolicys" :key="item.PID" :policy="item" to-detail />
                </template>
                <van-empty v-else description="暂无相关政策" />
            </van-tab>
            <van-tab title="相关通知" name="notice" v-if="typeId != 2">
                <template v-if="aboutNotices.length">
                    <PolicyCard v-for="item in aboutNotices" :key="item.PID" :policy="item" to-detail />
                </template>
                <van-empty v-else description="暂无相关通知" />
            </van-tab>
            <van-tab title="相关公示" name="publicty" v-if="typeId != 3">
                <template v-if="aboutPublicitys.length">
                    <PolicyCard v-for="item in aboutPublicitys" :key="item.PID" :policy="item" to-detail />
                </template>
                <van-empty v-else description="暂无相关公示" />
            </van-tab>
        </van-tabs>
    </div>
</template>
  
<script>
import PolicyCard from '@/components/policy-card';
import ProjectCard from '@/components/project-card2';
import PageLoading from '@/components/page-loading.vue'
import { pageOperate, wxPagePath, environment } from '@/appConfigData.js'
import { GetAdoptDetails, GetAboutProjectList, GetPolicyAttachmentList, GetCollection, GetAboutInfoList } from '@/api/policy.js'
import { ImagePreview } from 'vant';
// import { initFileUrl, downloaded } from '@/tool/utiles.js'
function usePromise() {
    let resolve,
        reject;
    const promise = new Promise((_resolve, _reject) => {
        resolve = _resolve;
        reject = _reject;
    });
    return { promise, resolve, reject }
}
export default {
    props: {
        policyId: String,
        channelId: String
    },
    components: {
        PolicyCard,
        ProjectCard,
        PageLoading
    },
    data() {
        return {
            environment: environment,
            fileId: '',
            typeId: '',
            tabVal: 'file-detail',
            pageLoading: true,
            currentTab: '',
            isCollection: false,
            policyDetail: {},
            attachList: [],
            policyImgs: [],
            aboutProjects: [],
            aboutPolicys: [],
            aboutNotices: [],
            aboutPublicitys: [],
            analysisPrivewPromise: usePromise(),
            operateText: pageOperate,
            policyContentStyle: `
          body {
            overflow: hidden;
            font-size: 16px;
            line-height: 1.5;
          }
          img {
            max-width: 100%;
          }
        `,

        }
    },
    created() {
        this.pageLoading = true;
        const { id, channelId } = this.$route.query;
        this.typeId = channelId || this.channelId || 1;
        this.fileId = id || this.policyId;
        const task = [
            this.getPolicyDetail(),
            this.getAboutProjects(),
            this.getAttachList(),
            this.getIsCollection()
        ]
        // 去掉公示分析放在启动加载中，
        if (this.typeId == 3) {
            //task.push(this.analysisPrivewPromise.promise);
            const analyPpromise = this.analysisPrivewPromise.promise;
        }
        Promise.all(task).finally(() => {
            this.pageLoading = false;
        })
        this.getAboutFiles();
    },
    watch: {
        '$route.query.id': function () {
            this.pageLoading = true;
            const { id, channelId } = this.$route.query;
            this.typeId = channelId || this.channelId || 1;
            this.fileId = id || this.policyId;
            this.tabVal = 'file-detail'
            const task = [
                this.getPolicyDetail(),
                this.getAboutProjects(),
                this.getAttachList(),
                this.getIsCollection()
            ]
            // 去掉公示分析放在启动加载中，
            if (this.typeId == 3) {
                //task.push(this.analysisPrivewPromise.promise);
                const analyPpromise = this.analysisPrivewPromise.promise;
            }

            Promise.all(task).finally(() => {
                this.pageLoading = false;
            })
            this.getAboutFiles();
        }
    },
    methods: {
        initFileUrl(url) {
            if (url) {
                if (/^https{0,1}:\/+/.test(url)) return url;
                return "http://static.10nnet.com/" + url;
            }
            return url;
        },
        downloaded(url) {
            if (this.environment == 'wx') {
                let path = this.initFileUrl(url);
                let backOrigin = window.location.origin
                let backPath = this.$route.path
                let backID = this.$route.query.id
                let backChannelID = this.$route.query.channelId
                let downType = 'detail'
                this.$wx.miniProgram.navigateTo({
                    url: `${wxPagePath}?downType=${downType}&downPath=${path}&backOrigin=${backOrigin}&backPath=${backPath}&backID=${backID}&backChannelID=${backChannelID}`,
                })
            } else if (this.environment == 'h5') {
                window.open(this.initFileUrl(url));
            }
        },
        init() {
            location.reload()
        },
        onClickPolicyPrivew(event) {
            const { target } = event;
            if (target instanceof Image) {
                const idx = this.policyImgs.findIndex(item => item === target.src);
                ImagePreview({
                    images: this.policyImgs,
                    startPosition: Math.max(idx, 0)
                })
            }
        },
        viewSource() {
            window.open(this.policyDetail.PageUrl);
        },
        onCollection() {
            if (this.isCollection) return;
            this.$http.post({
                url: "v1/WebCollection/Create",
                params: {
                    ResourcesPID: this.fileId,
                    ResourcesTitle: this.policyDetail.PolicyTitle,
                    channelid: this.policyDetail.ChannelId,
                    Source: this.policyDetail.Source,
                },
                loading: true
            }).then(() => {
                this.isCollection = true;
                this.$toast.success('收藏成功');
            })
        },
        onChangePolicyState(state) {
            this.policyDetail.States = state;
        },
        async getPolicyDetail() {
            const { fileId } = this;
            // Area: this.Global.AreaCode
            const res = await GetAdoptDetails({ pid: this.fileId })
            if (res.success) {
                const data = res.Data || {};
                this.policyDetail = data;
                if (data.typeId) {
                    this.typeId = data.typeId;
                }
                if (data.ChannelId) this.typeId = data.ChannelId;
                // this.setDocumentTitle(data.PolicyTitle);
                this.$nextTick(() => {
                    const imgEls = document.querySelectorAll('#policy-privew img');
                    const result = [];
                    imgEls.forEach(item => {
                        result.push(item.src);
                    })
                    this.policyImgs = result;
                })
            }
        },
        async getAboutProjects() {
            const res = await GetAboutProjectList({ pid: this.fileId })
            if (res.success) {
                this.aboutProjects = res.Data || [];
            }
        },
        async getAttachList() {
            const res = await GetPolicyAttachmentList({ pid: this.fileId })
            if (res.success) {
                this.attachList = res.Data || [];
            }
        },
        async getIsCollection() {
            const res = await GetCollection({ id: this.fileId })
            if (res.success) {
                this.isCollection = res.Data;
            }
        },
        getAboutFiles() {
            const { typeId } = this;
            const arr = [
                [1, 'aboutPolicys'],
                [2, 'aboutNotices'],
                [3, 'aboutPublicitys']
            ]
            // 不获取与当前类型相同的相关文件
            arr.forEach(item => {
                const [currentChannelId, field] = item;
                if (typeId != currentChannelId) {
                    this.sendRequestAboutFiles(field, { channelId: currentChannelId })
                }
            })
        },
        async sendRequestAboutFiles(field, params) {
            const res = await GetAboutInfoList({
                ...params,
                pid: this.fileId,
                // Area: this.Global.AreaCode,
            })
            if (res.success) {
                this[field] = res.Data || [];
            }
        },
    },
    computed: {
        tabs() {
            return [
                { title: '政策文件', value: 'policy' },
                { title: '相关通知', value: 'notice' },
                { title: '相关公示', value: 'publicity' }
            ]
        },
        collectionIcon() {
            return `/static/image/${this.isCollection ? "Collectiontrue" : "Collectionfalse"
                }.png`;
        },
        // followAd() {
        //     return (
        //         this.$store.getters
        //             .getAdListByChannelName("公众号")
        //             .find(item => item.ShowArea === "A1") || {}
        //     );
        // },
        currentTabName() {
            const map = {
                1: '政策文件',
                2: '申报通知',
                3: '申报公示'
            }
            return map[this.typeId];
        }
    }
}
</script>
  
<style scoped lang="less">
.policy-detail {
    padding-bottom: 200px;
    background: #fff;
}

.head {
    padding: 12px;

    .title {
        font-size: 20px;
        margin-bottom: 10px;
        color: #333;
    }

    .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;

        .left {
            color: #8f8f8f;
            font-size: .75rem !important;
        }

        .sign {
            margin-left: 4px;
            color: rgb(68, 137, 197);
        }

        .collection {
            display: flex;
            align-items: center;
            line-height: 1;

            .txt {
                margin-left: 4px;
            }
        }
    }
}

.follow-ad {
    font-size: 0;
}

.policy-main {
    padding: 12px;
    background: #fff;
}

.block {
    margin-top: 10px;
    background: #fff;

    .block-title {
        padding: 10px;
        font-size: 14px;
    }
}

.info {
    .info-item {
        padding: 16px 20px;
        font-size: 14px;
    }
}

#policy-privew {
    word-break: break-all;

    /deep/ img {
        max-width: 100%;
    }
}

.view-source {
    padding: 12px 0;
    font-size: 14px;
    color: #08f;
}

.attach {
    padding: 12px 0;

    a {
        font-size: 14px;
        color: #08f;
    }
}

.tabs {
    /deep/.van-tabs__wrap {
        border-bottom: 1px solid #bbb;
    }

    /deep/.van-tabs__line {
        width: 33.33%;
        height: 2px;
    }
}

.attach-item {
    line-height: 1.2;
    padding: 6px 0;
}

.share-button {
    position: fixed;
    right: 0;
    bottom: 170px;
    z-index: 666;
    width: 42px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2px 0;
    border: 1px solid #DDDDDD;
    border-radius: 4px 0 0 4px;
    font-size: 12px;
    color: #666666;
    background: #F8F8F8;

    .van-icon {
        color: rgb(7, 193, 96);
        font-size: 24px;
    }
}

.downText {
    color: #08f;
    cursor: pointer;
}
</style>