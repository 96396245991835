<!--  -->
<template>
  <div class="planning_box">
    <PageLoading :loading="pageLoading"></PageLoading>
    <div class="logoInfo">
      <div class="logo">
        <img src="@/static/image/ZCKCLOGO.png" width="58px" height="58px" alt />
      </div>
      <div class="info">
        <div class="text1">规划报告</div>
        <div class="text2">惠企政策-您身边的申报专家</div>
      </div>
    </div>
    <template>
      <div class="btn" @click="toPreview" v-track="{ triggerType: 'click', info: operateText.down }">下载规划报告
      </div>
      <div id="ResultFilePath" class="btn" @click="copy" :data-clipboard-text="ResultFilePath">复制文件地址</div>
    </template>
    <div class="text3">温馨提示：</div>
    <div class="text4">本规划报告基于过去12个月的政策、通知制定，会根据政府最新通知调整申报项目和申报时间，请及时浏览和下载最新规划报告。</div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { Toast } from "vant";
import { safeParse } from '@/tool/utiles.js';
import PageLoading from '@/components/page-loading.vue'
import { pageOperate, wxPagePath, environment } from '@/appConfigData.js'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { PageLoading },
  data() {
    //这里存放数据
    return {
      isGenerated: false,
      ViewResultFilePath: "",
      ResultFilePath: "",
      pageLoading: true,
      operateText: pageOperate,
      environment: environment
    };
  },
  //方法集合
  methods: {
    toPreview() {
      const { ViewResultFilePath, ResultFilePath } = this;
      let backOrigin = window.location.origin
      let backPath = this.$route.path
      let backSource = this.$route.query.source
      let downType = 'report'
      const serveReportData = safeParse(localStorage.getItem('serveReport_curPlanReportPush')) || {};
      if (/\.doc$/.test(ViewResultFilePath)) {
        if (ViewResultFilePath) {
          if (this.environment == 'wx') {
            this.$wx.miniProgram.navigateTo({
              url: `${wxPagePath}?downType=${downType}&downPath=${ViewResultFilePath}&backOrigin=${backOrigin}&backPath=${backPath}&backSource=${backSource}`,
            })
          } else if (this.environment == 'h5') {
            window.open(ViewResultFilePath);
          }
        } else if (serveReportData.ViewResultFilePath) {
          if (this.environment == 'wx') {
            this.$wx.miniProgram.navigateTo({
              url: `${wxPagePath}?downType=${downType}&downPath=${serveReportData.ViewResultFilePath}&backOrigin=${backOrigin}&backPath=${backPath}&backSource=${backSource}`,
            })
          } else if (this.environment == 'h5') {
            window.open(serveReportData.ViewResultFilePath);
          }
        }
      } else {
        if (ResultFilePath) {
          if (this.environment == 'wx') {
            this.$wx.miniProgram.navigateTo({
              url: `${wxPagePath}?downType=${downType}&downPath=${ResultFilePath}&backOrigin=${backOrigin}&backPath=${backPath}&backSource=${backSource}`,
            })
          } else if (this.environment == 'h5') {
            window.open(ResultFilePath);
          }
        } else if (serveReportData.ResultFilePath) {
          if (this.environment == 'wx') {
            this.$wx.miniProgram.navigateTo({
              url: `${wxPagePath}?downType=${downType}&downPath=${serveReportData.ResultFilePath}&backOrigin=${backOrigin}&backPath=${backPath}&backSource=${backSource}`,
            })
          } else if (this.environment == 'h5') {
            window.open(serveReportData.ResultFilePath);
          }
        }
      }
    },
    copy() {
      var clipboard = new ClipboardJS("#ResultFilePath");
      clipboard.on("success", e => {
        // success("复制成功"); //这里你如果引入了elementui的提示就可以用，没有就注释即可
        Toast.success("复制成功");
        console.log('复制成功');
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", e => {
        // 不支持复制
        console.log("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    }
  },
  created() {
    let self = this;
    this.source = this.$route.query.source;
    // 已经生成了规划报告
    if (this.source === 'serveReport') {
      const serveReport_curPlanReportPush = safeParse(localStorage.getItem('serveReport_curPlanReportPush')) || {};
      self.ResultFilePath = serveReport_curPlanReportPush.ResultFilePath; //FilePath
      self.ViewResultFilePath = serveReport_curPlanReportPush.ViewResultFilePath ? serveReport_curPlanReportPush.ViewResultFilePath : ''
      this.pageLoading = false;
    }
  },
};
</script>
<style scoped  lang="less">
/*@import url(); 引入公共css类*/
.planning_box {
  background: #eb644b;
  /* min-height: 100%; */
  height: 100vh;
  padding-top: 1px;
}

.logo {
  border-radius: 10px;
  background: #ffffff;
  width: 58px;
  height: 58px;
}

.logoInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
  margin-bottom: 55px;
}

.info {
  color: #ffffff;
  margin-left: 10px;
}

.text1 {
  font-size: 28px;
}

.text2 {
  font-size: 12px;
}

.btn {
  width: 166px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  color: #eb644b;
  background: #ffffff;
  margin: 0px auto;
  font-size: 14px;
  margin-bottom: 12px;
}

.btn a {
  color: #eb644b;
}

.text3 {
  text-align: center;
  font-size: 14px;
  color: #ffffff;
}

.text4 {
  font-size: 14px;
  color: #ffffff;
  margin: 0px 14px;
}
</style>