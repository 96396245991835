<template>
  <div class="project-card" :class="{ active: tapEffect }" @click="gotoDetail">
    <div class="flex">
      <div class="project-type">
        <span class="type-tag">{{ project.ClassName }}</span>
        <div class="type-quota">{{ project.SupportExplain }}</div>
        <div class="type-level">{{ project.GradeName }}</div>
      </div>
      <div class="project-info">
        <div class="row row-mr64">
          <span class="project-from">{{ project.Department }}</span>
          <!-- <span class="support-time tag" v-if="project.StartYear || project.EndYear">
          已获支持({{ getSupportTime(project.StartYear, project.EndYear) }})
        </span> -->
        </div>
        <div class="tags">
          <span class="support-time tag" v-if="project.StartYear || project.EndYear">
            已获支持({{ getSupportTime(project.StartYear, project.EndYear) }})
          </span>
        </div>
        <p v-if="project.ProjectTitleHot" class="project-name" v-html="project.ProjectTitleHot"></p>
        <p v-else class="project-name">{{ project.ProjectTitle }}</p>
        <p class="project-time">{{ project.DeclarationDesc }}</p>
        <van-rate v-if="$route.name == 'speed-match' || $route.name == 'exact-match'" size="10px" class="rate"
          v-model="project.Weight" readonly color="#ffd21e" />
      </div>
      <div class="r-tag tag" v-if="project.IsCultivation && showCultivation && $route.name != 'home'"
        @click.stop="$emit('onTapCultivation')">可培育</div>
    </div>
  </div>
</template>

<script>
import { isEmpty } from '@/tool/utiles.js'
export default {
  components: {},
  props: {
    project: {
      type: Object,
      required: true,
    },
    toDetail: {
      type: Boolean,
      default: false,
    },
    tapEffect: {
      type: Boolean,
      default: true
    },
    showCultivation: {
      type: Boolean,
      default: true
    },
    searchKey: {
      type: String,
      default: ""
    },
  },
  data() {
    return {};
  },
  watch: {
    project: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val.SearchKeyList && val.SearchKeyList.length) this.$nextTick(() => {
          let spanList = document.querySelectorAll('.words_value span')
          spanList.forEach((item) => {
            item.style.color = '#EA2D3F'
            item.style.fontStyle = 'normal';

          })
        })
        if (val.ProjectTitleHot) this.$nextTick(() => {
          let spanList = document.querySelectorAll('.project-name span')
          spanList.forEach((item) => {
            item.style.color = '#EA2D3F'
            item.style.fontStyle = 'normal';
          })
        })
      }
    }
  },
  created() { },
  methods: {
    setHtml(item) {
      let searchKey = this.searchKey || "";
      const div = document.createElement("div");
      div.innerHTML = item.Name;
      let innerText = div.innerText;
      let index = innerText.indexOf(searchKey)
      let len = searchKey.length;
      if (index != -1) innerText = innerText.slice(index - 10 >= 0 ? index - 10 : 0, index + 10 + len);
      if (searchKey)
        innerText = innerText.replaceAll(searchKey, "<span>" + searchKey + "</span>")
      return innerText
    },
    gotoDetail() {
      if (this.$store.state.loginState === false) {
        this.$store.commit('setLoginModalVisible', true)
        return
      }
      if (!this.toDetail) return;
      this.$router.push({
        path: "/other-project-detail",
        query: {
          id: this.project.PID,
          companyName: this.$route.query.companyName,
          companyId: this.$route.query.companyId,
          searchKey: this.searchKey
        },
      });
    },
    getSupportTime(start, end) {
      if (start == end) return start;
      if (isEmpty(start)) return end;
      if (isEmpty(end)) return start;
      return `${start}-${end}`;
    }
  },
};
</script>

<style scoped lang="less">
.project-card {
  position: relative;
  width: 100%;
  padding: 8px 0;
  background-color: #fff;
  border-top: 1px solid #eee;

  &.active:active {
    background: #eee;
  }

  .project-type {
    width: 76px;
    min-height: 60px;
    text-align: center;

    .type-tag {
      display: inline-block;
      height: 15px;
      line-height: 15px;
      font-size: 62.5%;
      color: #ffffff;
      padding: 0 5px;
      background: var(--primary);
      margin-bottom: 7px;
    }

    .type-quota {
      text-align: center;
      font-size: 13px;
      color: var(--primary);
      line-height: 13px;
      margin-bottom: 3px;
    }

    .type-level {
      text-align: center;
      font-size: 62.5%;
      color: #999;
    }
  }

  .project-info {
    flex: 1;
    min-height: 62px;
    padding: 0 12px;
    border-left: 1px solid #dddddd;
    overflow: hidden;
    position: relative;

    .rate {
      position: absolute;
      bottom: 20px;
      right: 10px;
      // transform: rotate(90deg);
    }

    .project-name {
      margin-right: 68px;
      font-size: 14px;
      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 2px;
    }

    .project-from {
      font-size: 12px;
      color: var(--primary);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 2px;
    }

    .project-time {
      font-size: 12px;
      color: #999999;
      text-overflow: ellipsis;
      white-space: nowrap;

      span {
        color: #efa55a;
        background: #fff4e3;
        padding: 1px 8px;
        margin-left: 10px;
      }
    }

    .row-mr64 {
      margin-right: 64px;
    }

  }

  .tag {
    white-space: nowrap;
    border-radius: 18px;
    padding: 2px 8px;
    color: var(--primary);
    font-size: 12px;
    background: rgba(255, 248, 230, 1);
  }

  .r-tag {
    position: absolute;
    top: 0;
    right: 0px;
    border-radius: 2px;
    // border-top-left-radius: 18px;
    // border-bottom-left-radius: 18px;
  }


  .hotWords {
    padding: 2px 12px;
    margin-top: 6px;

    // border-top: 1px solid #eee;
    .hotWords-box {
      padding-top: .625rem;
      padding-bottom: .625rem;
    }

    .words {
      // display: inline-block;
      font-size: 12px;
      font-weight: 400;
      color: #000000;

      b {
        font-weight: 400;
        color: #999;
      }

      .words_value {
        display: inline-block;
        width: 100%;
        // 最多显示两行 超出隐藏
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
}
</style>
