let env = process.env.NODE_ENV;
import { config } from "@/config.js";

const envDefault = "default";
const defaultConfig = config[envDefault];
// 首页文案配置
let list = defaultConfig.list;
// 埋点参数配置
let pageOperate = defaultConfig.pageOperate;
// 微信小程序接收的页面
let wxPagePath = defaultConfig.wxPagePath;
// 微信小程序跳转登录
let wxLoginPath = defaultConfig.wxLoginPath;
// 页面运行环境
let environment = defaultConfig.environment;
if (config[env]) {
  list = config[env].list;
  pageOperate = config[env].pageOperate;
  wxPagePath = config[env].wxPagePath;
  wxLoginPath = config[env].wxLoginPath;
  environment = config[env].environment;
}

export { list, pageOperate, wxPagePath, wxLoginPath, environment };
