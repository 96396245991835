<template>
  <div class="project-card" :class="{ active: tapEffect }" @click="gotoDetail">
    <div class="project-type">
      <span class="type-tag">{{ project.ClassName }}</span>
      <div class="type-quota">{{ project.SupportExplain }}</div>
      <div class="type-level">{{ project.GradeName }}</div>
    </div>
    <div class="project-info">
      <div class="row">
        <span class="project-from">{{ project.Department }}</span>
        <span class="support-time tag" v-if="project.StartYear || project.EndYear">
          已获支持({{ getSupportTime(project.StartYear, project.EndYear) }})
        </span>
      </div>
      <p class="project-name">{{ project.ProjectTitle }}</p>
      <p class="project-time">{{ project.DeclarationDesc }}</p>
    </div>
    <div v-if="project.IsCultivation && showCultivation && $route.name != 'home'" class="r-tag tag"
      @click.stop="$emit('onTapCultivation')">可培育</div>
  </div>
</template>

<script>
import { isEmpty } from '@/tool/utiles.js'
export default {
  components: {},
  props: {
    project: {
      type: Object,
      required: true,
    },
    toDetail: {
      type: Boolean,
      default: false,
    },
    tapEffect: {
      type: Boolean,
      default: true
    },
    showCultivation: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {};
  },
  created() { },
  methods: {
    gotoDetail() {
      if (this.$store.state.loginState === false) {
        this.$store.commit('setLoginModalVisible', true)
        return
      }
      if (!this.toDetail) return;
      this.$router.push({
        path: "/other-project-detail",
        query: {
          id: this.project.PID
        },
      });
    },
    getSupportTime(start, end) {
      if (start == end) return start;
      if (isEmpty(start)) return end;
      if (isEmpty(end)) return start;
      return `${start}-${end}`;
    }
  },
};
</script>

<style scoped lang="less">
.project-card {
  position: relative;
  display: flex;
  width: 100%;
  padding: 10px 0;
  background-color: #fff;

  &.active:active {
    background: #eee;
  }

  .project-type {
    width: 76px;
    min-height: 60px;
    text-align: center;

    .type-tag {
      display: inline-block;
      height: 15px;
      line-height: 15px;
      font-size: 62.5%;
      color: #ffffff;
      padding: 0 5px;
      background: var(--primary);
      margin-bottom: 7px;
    }

    .type-quota {
      text-align: center;
      font-size: 13px;
      color: var(--primary);
      line-height: 13px;
      margin-bottom: 3px;
    }

    .type-level {
      text-align: center;
      font-size: 62.5%;
      color: #999;
    }
  }

  .project-info {
    flex: 1;
    min-height: 62px;
    padding: 0 12px;
    border-left: 1px solid #dddddd;
    overflow: hidden;

    .project-name {
      margin-right: 48px;
      font-size: 14px;
      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 2px;
    }

    .project-from {
      font-size: 12px;
      color: var(--primary);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 2px;
    }

    .project-time {
      font-size: 12px;
      color: #999999;
      text-overflow: ellipsis;
      white-space: nowrap;

      span {
        color: #c29845;
        background: #fff4e3;
        padding: 1px 8px;
        margin-left: 10px;
      }
    }

  }

  .tag {
    white-space: nowrap;
    border-radius: 18px;
    padding: 2px 8px;
    color: var(--primary);
    font-size: 12px;
    background: rgba(255, 248, 230, 1);
  }

  .r-tag {
    position: absolute;
    top: 34px;
    right: 0px;
    border-radius: 0;
    border-top-left-radius: 18px;
    border-bottom-left-radius: 18px;
  }

}
</style>
