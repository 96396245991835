<template>
  <transition name="fade">
    <div class="page-loading" v-show="loading" :style="{ zIndex }">
      <div class="content">
        <div class="loading">
          <!-- <img src="@/static/image/logo.png" alt="" class="logo" /> -->
          <div class="svg-wrapper">
            <svg width="100" height="100" viewBox="0 0 100 100">
              <circle id="circle" cx="50" cy="50" r="30" fill="none" stroke-width="2" :stroke="styleConfig.primary" />
            </svg>
          </div>
        </div>
        <div class="slot-wrap">
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  components: {},
  props: {
    loading: Boolean,
    zIndex: {
      type: Number,
      default: 99998
    }
  },
  data() {
    return {
      styleConfig: {
        primary: '#4D91FF'
      }
    };
  },
  methods: {},
};
</script>

<style scoped lang="less">
.page-loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99998;
  background: #fff;

  .content {
    margin-top: -100px;

    .slot-wrap {
      padding: 0 20px;
      font-size: 14px;
      color: #454545;
    }
  }

  .loading {
    position: relative;
    border-radius: 50%;
    text-align: center;

    .logo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-top: -1px;
      width: 50px;

      img {
        width: 100%;
      }
    }

    .svg-wrapper {
      width: 100px;
      height: 100px;
      margin: 0 auto;
      animation: rotate 2s infinite;

      #circle {
        stroke-dasharray: 314, 314;
        animation: dash 1.5s linear infinite;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 314;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 130, 314;
    stroke-dashoffset: -50;
  }

  100% {
    stroke-dasharray: 130, 314;
    stroke-dashoffset: -188;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
