<template>
  <div class='question'>
    <div class="item" v-for="item in optionList" :key="item.PID">
      <div class="label">
        <p class="title">{{ item.title }}</p>
        <p class="desc min-txt" v-if="item.desc">({{ item.desc }})</p>
      </div>
      <input type="number" v-model="item.NumericalValue" placeholder="请输入" />
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    option: {
      type: Object,
      default: () => { },
      required: true
    }
  },
  computed: {
    optionList() {
      const { OptionList } = this.option || {};
      if (Array.isArray(OptionList)) {
        return OptionList.filter(item => item.SuitCode == 2).map(item => {
          if (item.OptionName.includes('(')) {
            const arr = item.OptionName.split('(');
            item.title = arr[0];
            item.desc = arr[1].slice(0, -1);
          } else {
            item.title = item.OptionName;
          }
          if (item.NumericalValue == 0) item.NumericalValue = '';
          return item;
        })
      }
      return [];
    }
  },
  data() {
    return {

    }
  },
  created() {

  },
  methods: {
    getAnswers() {
      return this.optionList;
    }
  }
}
</script>

<style scoped lang="less">
.question {
  margin-top: -20px;
  padding: 20px 0;
  background: #fff;
}

.item {
  padding: 14px 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;

  .label {
    width: 130px;
    margin-right: 10px;
    padding-right: 10px;
    text-align: right;
    border-right: 1px solid #eee;

    .title {
      font-size: 14px;
      color: #333;
    }
  }

  input {
    padding: 6px;
    font-size: 14px;
    color: #333;
    border: 1px solid #eee;
  }
}</style>
