import { request } from '../http/request'
import config from '@/serviceConfig/service'
const { host, prefix, serviceApi } = config
const { policypro } = host
const { api } = prefix
const { getDictionary, getProjectCategory, getRelease, getUserInfo } = serviceApi

export const GetDictionary = (data) => {
    return request({
        url: policypro + api + getDictionary,
        method: 'get',
        data,
    })
}

export const GetProjectCategory = (data) => {
    return request({
        url: policypro + api + getProjectCategory,
        method: 'get',
        data,
    })
}

export const GetRelease = (data) => {
    return request({
        url: policypro + api + getRelease,
        method: 'get',
        data,
    })
}

export const GetUserInfo = (data) => {
    return request({
        url: policypro + api + getUserInfo,
        method: 'get',
        data,
    })
}


