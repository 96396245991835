import { request } from '../http/request'
import config from '@/serviceConfig/service'
const { host, prefix, serviceApi } = config
const { policypro } = host
const { api } = prefix
const { getProjectList, getProjectDetails, getAboutList } = serviceApi

export const GetProjectList = (data) => {
    return request({
        url: policypro + getProjectList,
        method: 'post',
        data,
    })
}

export const GetProjectDetails = (data) => {
    return request({
        url: policypro + api + getProjectDetails,
        method: 'get',
        data,
    })
}

export const GetAboutList = (data) => {
    return request({
        url: policypro + api + getAboutList,
        method: 'get',
        data,
    })
}