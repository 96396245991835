// /pages/policy/newList/newList 征信小程序消息接收路径
// /pages/webview/webview 本地和测试环境样例小程序消息接收路径
// environment 使用环境 wx 微信小程序使用  h5 网页
const config = {
  default: {
    list: [
      {
        name: "政策汇集",
        type: "policy",
        imgSrc: require("@/static/image/icon_zchj@2x.png"),
      },
      {
        name: "政策速递",
        type: "project",
        imgSrc: require("@/static/image/icon_zcst@2x.png"),
      },
      {
        name: "智能推荐",
        type: "match",
        imgSrc: require("@/static/image/icon_zctj@2x.png"),
      },
    ],
    pageOperate: {
      policyList: {
        PageName: "政策汇集",
        EventNam: "列表访问",
        DeviceType: 1,
      },
      policyDetails: {
        PageName: "政策汇集",
        EventNam: "详情访问",
        DeviceType: 1,
      },
      projectList: {
        PageName: "政策速递",
        EventNam: "列表访问",
        DeviceType: 1,
      },
      projectDetails: {
        PageName: "政策速递",
        EventNam: "详情访问",
        DeviceType: 1,
      },
      match: {
        PageName: "政策推荐",
        EventNam: "列表访问",
        DeviceType: 1,
      },
      down: {
        PageName: "政策申报指引报告下载",
        EventNam: "政策申报指引报告下载",
        DeviceType: 1,
      },
    },
    wxPagePath: '/pages/policy/newList/newList',
    wxLoginPath: '/pages/login/login',
    environment: 'wx'
  },
  development: {
    list: [
      {
        name: "找政策",
        type: "policy",
        imgSrc: require("@/static/image/icon_zchj@2x.png"),
      },
      {
        name: "找项目",
        type: "project",
        imgSrc: require("@/static/image/icon_zcst@2x.png"),
      },
      {
        name: "智能匹配",
        type: "match",
        imgSrc: require("@/static/image/icon_zctj@2x.png"),
      },
    ],
    pageOperate: {
      policyList: {
        PageName: "找政策",
        EventNam: "列表访问",
        DeviceType: 1,
      },
      policyDetails: {
        PageName: "政策详情",
        EventNam: "详情访问",
        DeviceType: 1,
      },
      projectList: {
        PageName: "找项目",
        EventNam: "列表访问",
        DeviceType: 1,
      },
      projectDetails: {
        PageName: "项目详情",
        EventNam: "详情访问",
        DeviceType: 1,
      },
      match: {
        PageName: "智能匹配",
        EventNam: "列表访问",
        DeviceType: 1,
      },
      down: {
        PageName: "政策规划报告下载",
        EventNam: "政策规划报告下载",
        DeviceType: 1,
      },
    },
    wxPagePath: '/pages/webview/webview',
    wxLoginPath: '/pages/login/login',
    environment: 'wx'
  }
};
export { config };
