<template>
  <div class='perfect-info'>
    <PageLoading :loading="pageLoading">正在加载企业配置...</PageLoading>
    <van-sticky>
      <van-tabs v-model="tabVal" class="nav">
        <van-tab title="所属产业" :name="0"></van-tab>
        <van-tab title="经济指标" :name="1"></van-tab>
        <van-tab title="人才团队" :name="2"></van-tab>
      </van-tabs>
    </van-sticky>

    <div v-show="tabVal === 0">
      <div class="title">
        <p class="name" @click="toCompanyInfo">{{ companyName }}</p>
        <p class="desc">覆盖以下<span>产业</span>（可根据实际情况重选，最多5项）</p>
      </div>
      <IndustrySelect ref="industrySelect" :company-id="$route.query.companyId"
        :evaluation-id="$route.query.evaluationId" />
    </div>

    <div v-show="tabVal === 1">
      <div class="title">
        <p class="name" @click="toCompanyInfo">{{ companyName }}</p>
        <p class="desc">填写<span>经济指标</span>有助于更精准的匹配结果</p>
      </div>
      <Question :option="getQuestion('1000', '经济指标')" ref="questionBy1000" />
    </div>

    <div v-show="tabVal === 2">
      <div class="title">
        <p class="name" @click="toCompanyInfo">{{ companyName }}</p>
        <p class="desc">填写<span>人才团队</span>数据有助于更精准的匹配结果</p>
      </div>
      <Question :option="getQuestion('1003', '现有人才团队')" ref="questionBy1003" />
    </div>

    <div class="footer">
      <button class="main-btn" @click="viewResult">匹配结果</button>
    </div>
  </div>
</template>

<script>
import PageLoading from '@/components/page-loading.vue'
import IndustrySelect from './mind-match/industry-select';
import Question from './mind-match/question';
import { objArrJoin } from '@/tool/utiles.js';
import { SmartMatchSaveAnswer, GetSmartMatchList, GetQuestionList } from '@/api/matchProject.js'
export default {
  components: {
    IndustrySelect,
    Question,
    PageLoading
  },
  data() {
    return {
      pageLoading: true,
      tabVal: 0,
      companyName: '',
      companyId: '',
      evaluationId: '',
      questionList: []
    }
  },
  created() {
    this.initRouteQuery();
    this.pageLoading = true;
    Promise.all([
      this.getQuestionList()
    ]).finally(() => {
      this.pageLoading = false;
    })
  },
  methods: {
    toCompanyInfo() { },
    async viewResult() {
      const selectedIndustrys = this.$refs.industrySelect.getSelectedIndustrys();
      if (!selectedIndustrys.length) return this.$toast('请至少选择一个产业');
      this.$toast.loading({
        duration: 0,
        message: '正在提交...'
      })
      const questionBy1000 = this.$refs.questionBy1000.getAnswers() || [];
      const questionBy1003 = this.$refs.questionBy1003.getAnswers() || [];
      const answers = questionBy1000.concat(questionBy1003);
      const res = await SmartMatchSaveAnswer({ ...this.getSelIndustrys(), evaluationId: this.evaluationId, Answers: answers.map(item => ({ ConditionCode: item.OptionCode, Value: item.NumericalValue })) })
      if (res) {
        const answer = await GetSmartMatchList({ evaluationId: res.Data + '' })
        if (answer.Data) {
          this.$toast.clear();
          this.$router.push({
            path: '/other-speed-match',
            query: {
              id: res.Data,
              companyName: this.companyName,
              companyId: this.companyId
            }
          })
        } else {
          this.$toast.clear();
        }
      } else {
        this.$toast.clear();
      }
    },
    getSelIndustrys() {
      const selectedIndustrys = this.$refs.industrySelect.getSelectedIndustrys();
      const industryCodes = objArrJoin(selectedIndustrys, 'code');
      const industryNames = objArrJoin(selectedIndustrys, 'name');
      return { industryCodes, industryNames };
    },
    initRouteQuery() {
      const { companyName, companyId, evaluationId } = this.$route.query;
      Object.assign(this, {
        companyName,
        companyId,
        evaluationId
      })
    },
    getQuestion(pid, name) {
      return this.questionList.find(item => item.PID === pid || item.ProblemTitle === name) || {};
    },
    async getQuestionList() {
      const res = await GetQuestionList({ evaluationId: this.evaluationId })
      this.questionList = res.Data || [];
    }
  }
}
</script>

<style scoped lang="less">
.perfect-info {
  padding-bottom: 100px;
}

.company-name {
  padding-bottom: 0;
  font-size: 17px;
  font-weight: 600;
  color: #333;
  background: #fff;
}

.nav {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.title {
  padding: 10px;
  background: #fff;

  .name {
    font-size: 15px;
    font-weight: bold;
    color: #0088ff;
  }

  span {
    color: var(--primary);
  }

  .desc {
    font-size: 14px;
    color: #333;
  }
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 14px;
  padding-bottom: 0;

  // background: #fff;
  .main-btn {
    width: 100%;
    border-radius: 2px 2px 0 0;
    line-height: 42px;
    font-size: 14px;
    text-align: center;
    color: #ffffff;
    background: var(--primary);
    border: none;
  }
}
</style>
