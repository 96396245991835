<template>
  <div class="policy-card" :class="{ active: tapEffect }" @click="goToDetail">
    <p class="policy-list-t">{{ policy.Source }}</p>
    <p class="policy-title" v-html="policy.PolicyTitle"></p>
    <div class="policy-list-b">
      <div class="policyList-b-l">
        <span class="label">{{ policy.ChannelName || channelNameMap[policy.ChannelId] }}</span>
        {{ policy.ReleaseDate }} 阅读{{ policy.Reads }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    policy: {
      type: Object,
      required: true
    },
    toDetail: {
      type: Boolean,
      default: false,
    },
    tapEffect: {
      type: Boolean,
      default: true
    },
  },
  created() { },
  data() {
    return {
      channelNameMap: {
        1: '政策',
        2: '通知',
        3: '公示'
      }
    }
  },
  methods: {
    goToDetail() {
      if (this.$store.state.loginState === false) {
        this.$store.commit('setLoginModalVisible', true)
        return
      }
      if (!this.toDetail) return;
      const { PID, ChannelId } = this.policy;
      let path = ''
      const otherPathMap = {
        1: '/other-policy/other-policy-detail',
        2: '/other-policy/other-declaration-detail',
        3: '/other-policy/other-notice-detail'
      }
      path = otherPathMap[ChannelId]
      this.$router.push({
        path: path,
        query: { id: PID, channelId: ChannelId }
      });
    },
  },
}
</script>

<style scoped lang="less">
.policy-card {
  padding: 10px;
  background: #fff;

  &.active:active {
    background: #eee;
  }
}

.policy-list-t {
  font-size: 12px;
  color: #08f;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 4px;
}

.policy-title {
  font-size: 16px;
  color: #333333;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin-bottom: 7px;
}

.policy-list-b {
  overflow: hidden;
}

.policyList-b-l {
  float: left;
  font-size: 12px;
  color: #999999;
}

.policyList-b-l .label {
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 1px 0px 1px 0px;
  width: 26px;
  // height: 16px;
  border: 1px solid #df2b36;
  line-height: 14px;
  border-radius: 4px;
  text-align: center;
  font-size: 10px;
  color: #df2b36;
  vertical-align: middle;
}
</style>
